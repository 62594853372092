import React, { FormEvent } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/system';

import { Form } from '@core/web/look';

const noop = () => undefined;

type Props = {
  name?: string;
  onSubmit?: (event?: FormEvent<HTMLFormElement> | undefined) => void;
  actions?: React.ReactNode;
  cardSx?: SxProps;
  cardContentSx?: SxProps;
};

export const MainCard = React.forwardRef<HTMLFormElement, React.PropsWithChildren<Props>>((props, ref) => {
  const { name, onSubmit = noop, actions, cardSx, cardContentSx, children } = props;

  return (
    <Card sx={{ padding: 0, backgroundColor: '#192337', borderRadius: '8px', height: '100%', ...cardSx }}>
      <Form ref={ref} name={name} onSubmit={onSubmit} sx={{ width: '100%', height: '100%' }}>
        <CardContent
          component={Stack}
          sx={{
            padding: { xs: '16px', lg: '22px' },
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            ...cardContentSx,
          }}
        >
          {children}
        </CardContent>
        {actions || null}
      </Form>
    </Card>
  );
});
