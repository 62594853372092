import React from 'react';
import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import { Uploader } from '../look';

import { ResourceTypeEnum } from './enums';

type Props = {
  sx?: SxProps<Theme>;
  onChange: (files: Array<File> | null) => void;
  onChangeBehaviorState?: (p: {
    isDragAccept: boolean;
    isFileDialogActive: boolean;
    isDragActive: boolean;
    isDragReject: boolean;
    isFocused: boolean;
  }) => void;
};

export function MultiFileUploader({ sx, onChange, children }: React.PropsWithChildren<Props>) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Uploader
      multiple
      maxSize={20 * 1024 * 1024}
      accept={{
        [ResourceTypeEnum.Pdf]: ['.pdf', '.docx'],
        [ResourceTypeEnum.Docx]: ['.docx'],
      }}
      sx={sx}
      uploadFiles={(files, _, errors, reset) => {
        if (errors && !isEmpty(errors)) {
          enqueueSnackbar(
            errors.map((error) => `${error.file?.name || 'file'}: ${error.message}`),
            { variant: 'error' },
          );
        }

        onChange(files);
        reset();
      }}
    >
      {children}
    </Uploader>
  );
}
