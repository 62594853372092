import React from 'react';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/system';

type Props = {
  sx?: SxProps;
};
export function SidebarRight({ sx, children }: React.PropsWithChildren<Props>) {
  return (
    <Stack alignItems="center" justifyContent="center" sx={{ paddingLeft: '32px', flexBasis: '40%', ...sx }}>
      {children}
    </Stack>
  );
}
