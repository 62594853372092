/* eslint-disable react/static-property-placement */
import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

import { ErrorView } from './ErrorView';
import { Props } from './types';

// "Portal" component for actual ErrorView component to
// render to (directly under body). Prevents bugs as in #27.
export class ErrorOverlay extends React.Component<Props> {
  private el: HTMLDivElement | null;

  static displayName: string;

  componentDidMount() {
    this.el = document.createElement('div');
    document.body.append(this.el);
    this.renderErrorView();
  }

  componentDidUpdate() {
    this.renderErrorView();
  }

  componentWillUnmount() {
    if (this.el) {
      unmountComponentAtNode(this.el);
      this.el.remove();
      this.el = null;
    }
  }

  renderErrorView() {
    render(<ErrorView {...this.props} />, this.el);
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return null;
  }
}

ErrorOverlay.displayName = 'ErrorOverlay';
