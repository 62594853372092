import React from 'react';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

export function DataGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton disableElevation variant="outlined" sx={{ color: '#96A8C7', borderColor: '#425477' }} />
      <GridToolbarDensitySelector
        disableElevation
        variant="outlined"
        sx={{ color: '#96A8C7', borderColor: '#425477' }}
      />
      <GridToolbarExport disableElevation variant="outlined" sx={{ color: '#96A8C7', borderColor: '#425477' }} />
      <GridToolbarFilterButton
        componentsProps={{
          button: {
            disableElevation: true,
            variant: 'outlined',
            sx: { color: '#96A8C7', borderColor: '#425477' },
          },
        }}
      />
    </GridToolbarContainer>
  );
}
