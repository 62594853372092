import React from 'react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { PaletteMode, Theme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeConfig } from '@mui/material/styles';
import createTheme from '@mui/material/styles/createTheme';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import MuiThemeProvider from '@mui/styles/ThemeProvider';
import useTheme from '@mui/styles/useTheme';
import merge from 'lodash/merge';

import { createUseReducer } from '@wpa/redux-utils';

import { getBoxShadows, getThemeOptions, getTypography, palette } from './custom-theme';
import { initialState, rootReducer } from './themeSlice';
import { ViewState } from './types';

import './fonts/font.scss';

type Action = { type: 'changeTheme'; payload: PaletteMode };
type Reducer = (prevState: ThemeConfig, action: Action) => ThemeConfig;
type Props = { defaultTheme?: PaletteMode; projectThemeConfig?: Partial<ThemeConfig> };

const ThemeDispatchContext = React.createContext<React.Dispatch<Action> | null>(null);
const useReducer = createUseReducer<ViewState>('view', rootReducer, { cache: false });

export function ThemeProvider(props: React.PropsWithChildren<Props>): JSX.Element {
  const { defaultTheme = 'dark', projectThemeConfig = {}, children } = props;

  useReducer(initialState() as ViewState);
  const [themeConfig, dispatch] = React.useReducer<Reducer, ThemeConfig>(
    (state: ThemeConfig, action: Action): ThemeConfig => {
      if (action.type === 'changeTheme') {
        return {
          ...state,
          customization: { mode: action.payload, borderRadius: state?.customization?.borderRadius },
        };
      }

      return state;
    },
    merge(
      { colors: palette, getBoxShadows, getTypography, customization: { mode: defaultTheme, borderRadius: '14px' } },
      projectThemeConfig,
    ),
    (state) => state,
  );

  const memoizedTheme = React.useMemo(
    () => responsiveFontSizes(createTheme(getThemeOptions(themeConfig))),
    [themeConfig],
  );

  return (
    <MuiThemeProvider theme={memoizedTheme}>
      <EmotionThemeProvider theme={memoizedTheme}>
        <ThemeDispatchContext.Provider value={dispatch}>
          <CssBaseline />
          {children}
        </ThemeDispatchContext.Provider>
      </EmotionThemeProvider>
    </MuiThemeProvider>
  );
}

export const useChangeTheme = (): (() => void) => {
  const dispatch = React.useContext(ThemeDispatchContext);
  const theme = useTheme<Theme>();

  return React.useCallback(() => {
    if (dispatch) {
      dispatch({ type: 'changeTheme', payload: theme.palette.mode === 'light' ? 'dark' : 'light' });
    }
  }, [dispatch, theme.palette.mode]);
};
