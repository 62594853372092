/* eslint-disable no-nested-ternary */
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { SystemStyleObject } from '@mui/system';

type OwnerState = {
  color:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'light'
    | 'dark'
    | 'text'
    | 'white'
    | 'logo';
  fontWeight: 'light' | 'regular' | 'medium' | 'bold';
  textTransform: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
  verticalAlign: 'unset' | 'baseline' | 'sub' | 'super' | 'text-top' | 'text-bottom' | 'middle' | 'top' | 'bottom';
  textGradient: boolean;
  opacity: number;
};

// @ts-expect-error: noop
export const MdTypographyRoot = styled(Typography)<{ ownerState: OwnerState }>(({ theme, ownerState }) => {
  const { palette, typography, functions } = theme;
  const { color, textTransform, verticalAlign, fontWeight, opacity, textGradient } = ownerState;

  const { gradients, transparent } = palette;
  const { fontWeightLight, fontWeightRegular, fontWeightMedium, fontWeightBold } = typography;
  const { linearGradient } = functions;

  const fontWeights = {
    light: fontWeightLight,
    regular: fontWeightRegular,
    medium: fontWeightMedium,
    bold: fontWeightBold,
  };

  const gradientStyles = (): SystemStyleObject => ({
    backgroundImage:
      color !== 'inherit' && color !== 'text' && color !== 'white' && gradients[color]
        ? linearGradient(gradients[color].main, gradients[color].state, gradients[color].deg)
        : linearGradient(gradients.primary.main, gradients.primary.state),
    display: 'inline-block',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: transparent.main,
    position: 'relative',
    zIndex: 1,
  });

  return {
    opacity,
    textTransform,
    verticalAlign,
    textDecoration: 'none',
    // @ts-expect-error: noop
    color: color === 'inherit' ? 'inherit' : !palette[color] ? 'inherit' : palette[color].main,
    fontWeight: fontWeights[fontWeight],
    ...(textGradient && gradientStyles()),
  };
});
