import { Buffer } from 'buffer';

import { slugify } from 'transliteration';

type Order = 'asc' | 'desc';

function descendingComparator<T extends Record<string, any>, Key extends keyof T>(a: T, b: T, orderBy: Key) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator<T extends Record<string, any>, Key extends keyof T = string>(
  orderBy: Key,
  order: Order = 'asc',
): (a: T, b: T) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator<T, Key>(a, b, orderBy)
    : (a, b) => -descendingComparator<T, Key>(a, b, orderBy);
}

export function sanitizeString(string = '') {
  return string.replace(/[^\d A-Za-z]/g, '-').toLowerCase();
}

export const isUrlValid = (str: string): boolean => {
  const pattern = new RegExp(
    '^(http(s)?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '?(\\/[-a-z\\d%_.~+]*)*' + // path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator

  return pattern.test(str);
};

export const encodeBase64 = (data: any) => Buffer.from(data).toString('base64');

export const decodeBase64 = (data: any) => Buffer.from(data, 'base64').toString('ascii');

export function renameFile(originalFile: File, newName: string) {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
}

export function transliterateFileName(fileNameWithExt: string) {
  const splitFileNameWithExt = fileNameWithExt.split('.');
  const ext = splitFileNameWithExt.pop();
  const fileName = splitFileNameWithExt.join('.');
  const slugifyFileName = slugify(fileName, { separator: '_', unknown: '_', replace: { '-': '_', '.': '_' } });

  return `${slugifyFileName}.${ext || 'unknown'}`;
}
