import React from 'react';
import { Outlet } from 'react-router';

import { Store } from '../store/Store';

export function StepsRoot() {
  return (
    <Store>
      <Outlet />
    </Store>
  );
}
