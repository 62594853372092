import React from 'react';
import MaterialCheckbox, { CheckboxProps } from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

type Props = CheckboxProps & {
  label: string | React.ReactNode;
};

export function FormCheckbox({ label, ...props }: Props) {
  return (
    <FormGroup>
      <FormControlLabel control={<MaterialCheckbox {...props} />} label={label} />
    </FormGroup>
  );
}
