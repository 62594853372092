import { DateValidationError, getFormatAndMaskByViews, validateDate } from '../_helpers/date-utils';
import { OverrideParsableDateProps, useParsedDate } from '../_shared/hooks/date-helpers-hooks';
import { useUtils } from '../_shared/hooks/useUtils';
import { makeValidationHook, ValidationProps } from '../_shared/hooks/useValidation';
import { defaultMaxDate, defaultMinDate, ParsableDate } from '../constants/prop-types';
import { AllPickerProps, makePickerWithStateAndWrapper, SharedPickerProps } from '../Picker/makePickerWithState';
import { WithViewsProps } from '../Picker/SharedPickerProps';
import { ExportedCalendarViewProps } from '../views/Calendar/CalendarView';
import { ResponsiveWrapper } from '../wrappers/ResponsiveWrapper';
import { DesktopWrapper, MobileWrapper, SomeWrapper, StaticWrapper } from '../wrappers/Wrapper';

import { DatePickerToolbar } from './DatePickerToolbar';

export type DatePickerView = 'year' | 'date' | 'month';

export interface BaseDatePickerProps<TDate>
  extends WithViewsProps<'year' | 'date' | 'month'>,
    ValidationProps<DateValidationError, ParsableDate>,
    OverrideParsableDateProps<TDate, ExportedCalendarViewProps<TDate>, 'minDate' | 'maxDate'> {}

const datePickerConfig = {
  useValidation: makeValidationHook<DateValidationError, ParsableDate, BaseDatePickerProps<unknown>>(validateDate),
  DefaultToolbarComponent: DatePickerToolbar,
  useInterceptProps: ({
    openTo = 'date',
    views = ['year', 'date'],
    minDate: __minDate = defaultMinDate,
    maxDate: __maxDate = defaultMaxDate,
    ...other
  }: AllPickerProps<BaseDatePickerProps<unknown>>) => {
    const utils = useUtils();
    const minDate = useParsedDate(__minDate);
    const maxDate = useParsedDate(__maxDate);

    return {
      views,
      openTo,
      minDate,
      maxDate,
      ...getFormatAndMaskByViews(views, utils),
      ...other,
    };
  },
};

type DatePickerComponent<TWrapper extends SomeWrapper> = <TDate>(
  props: BaseDatePickerProps<TDate> & SharedPickerProps<TDate, TWrapper>,
) => JSX.Element;

export const DatePicker = makePickerWithStateAndWrapper<BaseDatePickerProps<unknown>>(ResponsiveWrapper, {
  name: 'MuiDatePicker',
  ...datePickerConfig,
}) as DatePickerComponent<typeof ResponsiveWrapper>;

export type DatePickerProps = React.ComponentProps<typeof DatePicker>;

export const MobileDatePicker = makePickerWithStateAndWrapper<BaseDatePickerProps<unknown>>(MobileWrapper, {
  name: 'MuiMobileDatePicker',
  ...datePickerConfig,
}) as DatePickerComponent<typeof MobileWrapper>;

export type MobileDatePickerProps = React.ComponentProps<typeof MobileDatePicker>;

export const DesktopDatePicker = makePickerWithStateAndWrapper<BaseDatePickerProps<unknown>>(DesktopWrapper, {
  name: 'MuiDesktopDatePicker',
  ...datePickerConfig,
});

export type DesktopDatePickerProps = React.ComponentProps<typeof DesktopDatePicker>;

export const StaticDatePicker = makePickerWithStateAndWrapper<BaseDatePickerProps<unknown>>(StaticWrapper, {
  name: 'MuiStaticDatePicker',
  ...datePickerConfig,
}) as DatePickerComponent<typeof StaticWrapper>;

export type StaticDatePickerProps = React.ComponentProps<typeof StaticDatePicker>;
