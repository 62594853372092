import React from 'react';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';
import Typography from '@mui/material/Typography';

export function CardText({ children, ...props }: DefaultComponentProps<any>) {
  return (
    <Typography variant="body2" color="text.secondary" {...props}>
      {children}
    </Typography>
  );
}
