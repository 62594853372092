import React, { memo } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { SxProps, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { omit } from 'lodash';

type Props = DropzoneOptions & {
  sx?: SxProps<Theme>;
  onChangeBehaviorState?: (p: {
    isDragAccept: boolean;
    isFileDialogActive: boolean;
    isDragActive: boolean;
    isDragReject: boolean;
    isFocused: boolean;
  }) => void;
};

export const BaseUploader = memo((props: React.PropsWithChildren<Props>) => {
  const { sx, children } = props;

  const { getRootProps, getInputProps } = useDropzone(omit(props, ['sx', 'children']));

  return (
    <Box sx={sx} {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      {children}
    </Box>
  );
});
