import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react';
import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

import { getComparator } from '@core/shared';
import { ActionCreator } from '@wpa/redux-utils';

import { useOnClickOutside } from '../../../hooks';
import { AppRouteTypeEnum } from '../../../router/enums';
import { AppRoute } from '../../../router/types';
import { ViewState } from '../../../theme';
import { NavLink } from '../../material/NavLink';
import { MdBox } from '../../override-components/MdBox';
import { MdTypography } from '../../override-components/MdTypography';
import { mergeSx } from '../../utils';
import { Logo } from '../Logo';
import { MdScrollbar } from '../MdScrollBar';

import { sidenavLogoLabel } from './styles/sidenav';
import { SidenavCollapse } from './SidenavCollapse';
import { SidenavRoot } from './SidenavRoot';

type Props<Roles> = {
  logoSrc: string;
  logoAlt: string;
  brandName: string;
  authzAudience: string;
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
  routes: Array<AppRoute<Roles>>;
  miniSidenav: ViewState['miniSidenav'];
  setMiniSidenav: ActionCreator<any>;
};

export function Sidenav<Roles>(props: Props<Roles>) {
  const { logoSrc, logoAlt, brandName, authzAudience, color = 'info', routes, miniSidenav, setMiniSidenav } = props;
  const containerElement = React.useRef<HTMLDivElement>(null);
  const closeSidenav = () => setMiniSidenav(true);

  useOnClickOutside(containerElement, (event) => {
    if ((event.target as any)?.ariaLabel === 'Toggle menu') return;
    if ((event.target as any)?.parentElement?.ariaLabel === 'Toggle menu') return;
    closeSidenav();
  });

  const { user } = useAuth0();
  const { t } = useTranslation();
  const location = useLocation();

  const collapseName = location.pathname.split('/').slice(1)[0] || '/';

  return (
    <SidenavRoot ref={containerElement} variant="permanent" ownerState={{ miniSidenav }}>
      <MdBox pt={2} px={1} textAlign="center">
        <MdBox
          onClick={closeSidenav}
          display={{ xs: 'block', lg: 'none' }}
          sx={{ cursor: 'pointer', position: 'absolute', top: 0, right: 0, padding: 1.625 }}
        >
          <MdTypography variant="h6" color="text">
            <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
          </MdTypography>
        </MdBox>
        <MdBox component={NavLink} to="/" onClick={closeSidenav} display="flex" alignItems="center">
          <MdBox sx={mergeSx(sidenavLogoLabel, { display: 'flex', alignItems: 'center', margin: '0 auto' })}>
            <MdBox display="flex" sx={mergeSx(sidenavLogoLabel, { mr: miniSidenav ? 0 : 1 })}>
              <Logo src={logoSrc} alt={logoAlt} />
            </MdBox>
            <MdTypography
              sx={mergeSx(sidenavLogoLabel, {
                opacity: miniSidenav ? 0 : 1,
                maxWidth: miniSidenav ? 0 : '100%',
                margin: '0 auto',
              })}
            >
              {brandName}
            </MdTypography>
          </MdBox>
        </MdBox>
      </MdBox>
      <MdScrollbar width={3} height="calc(100% - 30px)" top={1} noScrollX>
        <List>
          {routes.sort(getComparator('index')).map((appRoute) => {
            const { type } = appRoute;
            const roles = user ? user[`${authzAudience}/roles`] : [];

            switch (type) {
              case AppRouteTypeEnum.NAVIGATION: {
                const { key, path, nameTKey, iconElement, allowedRoles, handleHasRole } = appRoute;

                const isAccessAllowed = handleHasRole(allowedRoles, roles);

                return isAccessAllowed ? (
                  <NavLink key={key} to={path} onClick={closeSidenav}>
                    <SidenavCollapse
                      color={color}
                      name={<Typography>{t(nameTKey)}</Typography>}
                      icon={iconElement}
                      active={key === collapseName}
                      miniSidenav={miniSidenav}
                    />
                  </NavLink>
                ) : null;
              }
              case AppRouteTypeEnum.COLLAPSE: {
                const { key, href, nameTKey, iconElement, allowedRoles, handleHasRole } = appRoute;

                const isAccessAllowed = handleHasRole(allowedRoles, roles);

                return isAccessAllowed ? (
                  <Link href={href} key={key} target="_blank" rel="noreferrer" sx={{ textDecoration: 'none' }}>
                    <SidenavCollapse
                      color={color}
                      name={<Typography>{t(nameTKey)}</Typography>}
                      icon={iconElement}
                      active={key === collapseName}
                      miniSidenav={miniSidenav}
                    />
                  </Link>
                ) : null;
              }
              // case AppRouteTypeEnum.TITLE: {
              //   const { key, titleTKey } = appRoute;
              //
              //   return (
              //     <MdTypography
              //       key={key}
              //       color="white"
              //       display="block"
              //       variant="caption"
              //       fontWeight="bold"
              //       textTransform="uppercase"
              //       pl={3}
              //       mt={2}
              //       mb={1}
              //       ml={1}
              //     >
              //       {titleTKey}
              //     </MdTypography>
              //   );
              // }
              // case AppRouteTypeEnum.DIVIDER: {
              //   const { key } = appRoute;
              //
              //   return <Divider key={key} />;
              // }
              default: {
                return null;
              }
            }
          })}
        </List>
      </MdScrollbar>
    </SidenavRoot>
  );
}
