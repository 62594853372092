import { pxToRem } from './pxToRem';
import { rgba } from './rgba';

export function boxShadow(
  offset: Array<number> = [],
  radius: Array<number> = [],
  color: string,
  opacity: number,
  inset = '',
) {
  const [x, y] = offset;
  const [blur, spread] = radius;

  return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${rgba(color, opacity)}`;
}
