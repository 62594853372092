import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Transition } from 'history';

import { useBlocker } from './useBlocker';

export function useCallbackPrompt(when: boolean): [boolean, () => void, () => void] {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPrompt, setShowPrompt] = React.useState(false);
  const [lastLocation, setLastLocation] = React.useState<any>(null);
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false);

  const cancelNavigation = React.useCallback(() => {
    setShowPrompt(false);
  }, []);

  // handle blocking when user click on another route prompt will be shown
  const handleBlockedNavigation = React.useCallback(
    (nextLocation: Transition) => {
      // in if condition we are checking next location and current location are equals or not
      if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
        setShowPrompt(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmedNavigation],
  );

  const confirmNavigation = React.useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
  }, []);

  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedNavigation, lastLocation]);

  useBlocker(handleBlockedNavigation, when);

  return [showPrompt, confirmNavigation, cancelNavigation];
}
