import React from 'react';
import { isMobile } from 'react-device-detect';
import { useMatches, useOutlet } from 'react-router';
import Stack from '@mui/material/Stack';
import { isEmpty } from 'lodash';

import { MdBox } from '@core/web/look';

import { ChipLabel } from './ChipLabel';

type Handle = { sidebar?: React.ReactNode; activeStep?: number };

type Props = {
  main: React.ReactNode;
  sidebar: React.ReactNode;
};

export function Layout({ main, sidebar }: Props) {
  const outlet = useOutlet();
  const matches = useMatches();

  const matchedSidebar = matches
    .filter((match) => Boolean((match.handle as Handle)?.sidebar))
    .map((match) => (match.handle as Handle)?.sidebar);
  const matchedActiveStep = matches
    .filter((match) => Boolean((match.handle as Handle)?.activeStep))
    .map((match) => (match.handle as Handle)?.activeStep);

  return (
    <Stack direction={isMobile ? 'column-reverse' : 'row'} sx={{ flexGrow: 1 }}>
      <Stack sx={{ flexGrow: 1 }}>
        <Stack direction="row" spacing={1} sx={{ marginBottom: '8px' }}>
          <ChipLabel title="1" text="Uploading" active={matchedActiveStep?.[0] === 1} />
          <ChipLabel title="2" text="Test your bot" active={matchedActiveStep?.[0] === 2} />
        </Stack>
        <Stack sx={{ flexGrow: 1 }}>{outlet || main}</Stack>
      </Stack>
      {isEmpty(matchedSidebar) ? sidebar : matchedSidebar}
    </Stack>
  );
}
