import React from 'react';
import Box from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

type Props = CircularProgressProps & { value: number };

function id(): string {
  return `_${Math.random().toString(36).slice(2, 9)}`;
}

const useStyles = makeStyles(() => ({
  circle: (options: { progress: number; gradientId: string }) => ({
    fill: 'none',
    strokeLinecap: 'round',
    stroke: `url(#${options.gradientId})`,
  }),
}));

export function CircularProgressWithLabel(props: Props) {
  const { value, ...otherProps } = props;

  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        const increment = prevProgress % 2 === 0 ? 5 : 3;

        if (prevProgress >= 70) {
          clearInterval(timer);
          return 75;
        }

        return prevProgress + increment;
      });
    }, 1800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const startColor = '#FFEE9E';
  const middleColor = '#FE85A6';
  const endColor = '#AE8DF5';
  const gradientId = id();
  const classes = useStyles({ progress: value, gradientId });

  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <svg width="0" height="0">
        <defs>
          <linearGradient id={gradientId} x1="100%" y1="50%" x2="0%" y2="0%" gradientTransform="rotate(-15)">
            <stop offset="0%" stopColor={startColor} />
            <stop offset="50%" stopColor={middleColor} />
            <stop offset="90%" stopColor={endColor} />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgress variant="determinate" classes={{ circle: classes.circle }} value={progress} {...otherProps} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          <Typography variant="h3" component="div">{`${Math.round(progress)}%`}</Typography>
          <Typography variant="h6" component="div" color="text.secondary">
            Processing...
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
}
