import React from 'react';
import { RouterProvider, useRouteError } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import { MdBox } from '@core/web/look';

import { DocumentsCard, FinishingCard, ProcessingCard } from '@app/components/onboarding/Cards';
import {
  FinishingStepDescription,
  ProcessingStepDescription,
  UploadingStepDescription,
} from '@app/components/onboarding/Descriptions';
import { UploadFilesForm, UserEmailForm } from '@app/components/onboarding/Forms';

import { StepsWidget } from './components/StepsWidget';
import { NavigateWithQuery } from './helpers/NavigateWithQuery';
import { Root } from './components';

function ErrorPage() {
  const error = useRouteError() as { statusText: string; message: string };
  console.error(error);

  return (
    <MdBox id="error-page">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error.statusText || error.message}</i>
      </p>
    </MdBox>
  );
}

export function Router() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: '/',
          element: <NavigateWithQuery to="/steps/1" />,
        },
        {
          path: '/steps',
          element: <NavigateWithQuery to="/steps/1" />,
        },
        {
          path: '/steps/1',
          element: (
            <StepsWidget
              leftSide={
                <>
                  <UploadingStepDescription isActive>
                    <UploadFilesForm />
                  </UploadingStepDescription>
                  <ProcessingStepDescription isActive={false} />
                </>
              }
              rightSide={<DocumentsCard sx={{ backgroundColor: '#192337' }} />}
            />
          ),
        },
        {
          path: '/steps/2',
          element: (
            <StepsWidget
              leftSide={
                <>
                  <UploadingStepDescription isActive={false} />
                  <ProcessingStepDescription isActive />
                </>
              }
              rightSide={
                <ProcessingCard sx={{ backgroundColor: '#192337' }}>
                  <UserEmailForm />
                </ProcessingCard>
              }
            />
          ),
        },
        {
          path: '/steps/confirm',
          element: <StepsWidget leftSide={<FinishingStepDescription />} rightSide={<FinishingCard />} />,
        },
        // {
        //   path: '/steps',
        //   element: <Navigate to="/steps/1" />,
        // },
        // {
        //   path: '/steps',
        //   element: <StepsRoot />,
        //   children: [
        //     {
        //       path: '1',
        //       handle: {
        //         activeStep: 1,
        //       },
        //       element: <Layout main={<DocumentUploaderRoot />} sidebar={<WelcomeText key="sidebar" />} />,
        //       children: [
        //         {
        //           path: 'url',
        //           element: <FromUrl />,
        //         },
        //         {
        //           path: 'list',
        //           element: <DataList />,
        //         },
        //       ],
        //     },
        //     {
        //       path: '2',
        //       handle: {
        //         activeStep: 2,
        //       },
        //       element: (
        //         <Layout
        //           main={<GetEmail />}
        //           sidebar={
        //             isBrowser && (
        //               <SidebarRight key="sidebar">
        //                 <CircularProgressWithLabel value={75} style={{ width: '320px', height: '320px' }} />
        //               </SidebarRight>
        //             )
        //           }
        //         />
        //       ),
        //       children: [
        //         {
        //           path: 'confirm',
        //           handle: {
        //             sidebar: isBrowser && (
        //               <SidebarRight key="sidebar">
        //                 <EmailIcon viewBox="0 0 528 476" sx={{ width: '100%', height: '100%' }} />
        //               </SidebarRight>
        //             ),
        //           },
        //           element: <ConfirmationSent />,
        //         },
        //       ],
        //     },
        //   ],
        // },
      ],
    },
  ]);
  return <RouterProvider router={router} />;
}
