import React from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet } from 'react-router';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { Logo, MdBox } from '@core/web/look';
import { app } from '@metadialog/landing-config';

export function Root() {
  return (
    <MdBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: { xs: 'initial', lg: 'inherit' },
        maxHeight: { xs: 'inherit', lg: '100vh' },
        background: '#111827',
      }}
    >
      <MdBox
        sx={{
          padding: '16px 0',
          display: 'flex',
          justifyContent: 'center',
          borderBottom: '1px solid #233251',
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            gap: 5,
            alignItems: 'center',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          <Logo
            withText
            src='https://metadialog.fxexpertsnetwork.com/wp-content/uploads/2023/09/metadialog-main-logo.svg'
            alt={app.name}
            // textSx={{ fontSize: { xs: '16px', lg: '20px' } }}
            containerSx={{ height: 'inherit' }}
            imageSx={{ width: '150px', height: 'auto' }}
          />
          <Divider sx={{ display: { xs: 'none', lg: 'block' } }} orientation="vertical" />
          <Typography
            sx={{
              display: { xs: 'none', lg: 'block' },
              fontFamily: 'Aeonik Fono',
              color: '#DAEDFF',
              fontSize: { xs: 11, lg: 16 },
            }}
          >
            Welcome to Metadialog Conversational AI Platform!
          </Typography>
        </Stack>
      </MdBox>
      <Stack
        sx={{ flexGrow: 1, height: { lg: '1px' }, maxHeight: '100%', padding: { xs: '16px', lg: '32px 32px 48px' } }}
      >
        <Outlet />
      </Stack>
    </MdBox>
  );
}
