export function hexToRgb(hexCode: string) {
  let hex = hexCode;
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([\da-f])([\da-f])([\da-f])$/i;
  hex = hex.replace(shorthandRegex, (_m, r, g, b) => r + r + g + g + b + b);

  const rgbColor = /^#?([\da-f]{2})([\da-f]{2})([\da-f]{2})$/i.exec(hex);

  if (rgbColor) {
    return [Number.parseInt(rgbColor[1], 16), Number.parseInt(rgbColor[2], 16), Number.parseInt(rgbColor[3], 16)].join(
      ', ',
    );
  }

  throw new Error(`unknown hex color: ${hex}`);
}
