import React from 'react';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles, useTheme } from '@mui/styles';
import clsx from 'clsx';

import { DateRangeIcon } from '../_shared/icons/DateRange';
import { TimeIcon } from '../_shared/icons/Time';
import { WrapperVariantContext } from '../wrappers/WrapperVariantContext';

import { DateTimePickerView } from './DateTimePicker';

const viewToTabIndex = (openView: DateTimePickerView) => {
  if (openView === 'date' || openView === 'year') {
    return 'date';
  }

  return 'time';
};

const tabIndexToView = (tab: DateTimePickerView) => {
  if (tab === 'date') {
    return 'date';
  }

  return 'hours';
};

export interface DateTimePickerTabsProps {
  dateRangeIcon?: React.ReactNode;
  onChange: (view: DateTimePickerView) => void;
  timeIcon?: React.ReactNode;
  view: DateTimePickerView;
}

export const useStyles = makeStyles(
  (theme) => {
    const tabsBackground =
      theme.palette.type === 'light' ? theme.palette.primary.main : theme.palette.background.default;

    return {
      root: {},
      modeDesktop: {
        order: 1,
      },
      tabs: {
        color: theme.palette.getContrastText(tabsBackground),
        backgroundColor: tabsBackground,
      },
    };
  },
  { name: 'MuiDateTimePickerTabs' },
);

const DateTimePickerTabs: React.FC<DateTimePickerTabsProps> = (props) => {
  const { dateRangeIcon = <DateRangeIcon />, onChange, timeIcon = <TimeIcon />, view } = props;
  const classes = useStyles();
  const theme = useTheme();
  const wrapperVariant = React.useContext(WrapperVariantContext);
  const indicatorColor = theme.palette.type === 'light' ? 'secondary' : 'primary';

  const handleChange = (e: React.ChangeEvent<{}>, value: DateTimePickerView) => {
    if (value !== viewToTabIndex(view)) {
      onChange(tabIndexToView(value));
    }
  };

  return (
    <Paper className={clsx(classes.root, { [classes.modeDesktop]: wrapperVariant === 'desktop' })}>
      <Tabs
        variant="fullWidth"
        value={viewToTabIndex(view)}
        onChange={handleChange}
        className={classes.tabs}
        indicatorColor={indicatorColor}
      >
        <Tab value="date" aria-label="pick date" icon={<>{dateRangeIcon}</>} />
        <Tab value="time" aria-label="pick time" icon={<>{timeIcon}</>} />
      </Tabs>
    </Paper>
  );
};

export default DateTimePickerTabs;
