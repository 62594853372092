import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function EmailIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g clipPath="url(#clip0_199_8431)">
        <g filter="url(#filter0_f_199_8431)">
          <ellipse cx="267.396" cy="418.495" rx="196.09" ry="17.8264" fill="#111827" />
        </g>
        <path
          d="M410.855 331.061V220.502C410.855 211.638 406.53 203.331 399.268 198.248L279.577 114.466C270.224 107.919 257.776 107.919 248.423 114.466L128.731 198.248C121.469 203.331 117.145 211.638 117.145 220.502V331.061H410.855Z"
          fill="#2C3B58"
        />
        <path
          d="M344.643 151.1H171.473C163.971 151.1 157.891 157.18 157.891 164.682V400.669C157.891 408.17 163.971 414.251 171.473 414.251H344.643C352.144 414.251 358.225 408.17 358.225 400.669V164.682C358.225 157.18 352.144 151.1 344.643 151.1Z"
          fill="white"
        />
        <g clipPath="url(#clip1_199_8431)">
          <rect x="225.732" y="173.103" width="14.5582" height="14.5582" fill="#2676E7" />
          <path
            d="M233.896 184.283L232.936 183.322L235.134 181.188L232.936 179.054L233.896 178.094L236.997 181.188L233.896 184.283Z"
            fill="white"
          />
          <path
            d="M232.154 182.827L229.06 179.733L232.154 176.638L233.121 177.599L230.916 179.733L233.121 181.867L232.154 182.827Z"
            fill="white"
          />
        </g>
        <path
          d="M248.693 184.08C247.975 184.08 247.363 183.921 246.855 183.603C246.348 183.281 245.959 182.843 245.688 182.288C245.422 181.734 245.287 181.106 245.282 180.406C245.287 179.692 245.426 179.06 245.701 178.51C245.981 177.956 246.376 177.521 246.888 177.208C247.399 176.89 248.007 176.731 248.712 176.731C249.503 176.731 250.171 176.93 250.717 177.33C251.267 177.726 251.626 178.267 251.794 178.955L250.247 179.374C250.126 179 249.926 178.71 249.647 178.504C249.368 178.293 249.05 178.188 248.693 178.188C248.289 178.188 247.956 178.284 247.693 178.478C247.431 178.667 247.238 178.929 247.113 179.264C246.989 179.6 246.926 179.98 246.926 180.406C246.926 181.067 247.075 181.603 247.371 182.011C247.668 182.419 248.108 182.623 248.693 182.623C249.105 182.623 249.43 182.529 249.666 182.34C249.907 182.151 250.088 181.878 250.208 181.521L251.794 181.875C251.579 182.585 251.203 183.131 250.666 183.513C250.128 183.891 249.471 184.08 248.693 184.08Z"
          fill="#374768"
        />
        <path
          d="M255.676 184.08C254.971 184.08 254.352 183.928 253.819 183.623C253.286 183.317 252.869 182.894 252.568 182.353C252.272 181.811 252.123 181.188 252.123 180.483C252.123 179.722 252.269 179.062 252.562 178.504C252.854 177.941 253.26 177.504 253.78 177.195C254.3 176.885 254.902 176.731 255.585 176.731C256.308 176.731 256.92 176.9 257.423 177.24C257.93 177.575 258.306 178.05 258.551 178.665C258.796 179.279 258.889 180.004 258.828 180.837H257.288V180.27C257.283 179.514 257.15 178.961 256.888 178.613C256.626 178.265 256.213 178.091 255.65 178.091C255.014 178.091 254.541 178.289 254.232 178.684C253.922 179.075 253.767 179.649 253.767 180.406C253.767 181.11 253.922 181.656 254.232 182.043C254.541 182.43 254.992 182.623 255.585 182.623C255.968 182.623 256.297 182.54 256.572 182.372C256.851 182.2 257.066 181.953 257.217 181.63L258.751 182.095C258.485 182.722 258.072 183.21 257.513 183.558C256.959 183.906 256.346 184.08 255.676 184.08ZM253.277 180.837V179.664H258.068V180.837H253.277Z"
          fill="#374768"
        />
        <path
          d="M259.88 183.887V176.924H261.253V178.62L261.086 178.4C261.172 178.168 261.286 177.958 261.427 177.769C261.574 177.575 261.748 177.416 261.95 177.292C262.122 177.175 262.311 177.085 262.517 177.021C262.728 176.952 262.943 176.911 263.162 176.898C263.381 176.881 263.594 176.89 263.8 176.924V178.375C263.594 178.314 263.355 178.295 263.084 178.317C262.818 178.338 262.577 178.413 262.362 178.542C262.147 178.658 261.971 178.807 261.834 178.987C261.7 179.168 261.602 179.374 261.537 179.606C261.473 179.834 261.44 180.081 261.44 180.347V183.887H259.88Z"
          fill="#374768"
        />
        <path
          d="M267.683 184.08C266.978 184.08 266.359 183.928 265.826 183.623C265.293 183.317 264.876 182.894 264.576 182.353C264.279 181.811 264.131 181.188 264.131 180.483C264.131 179.722 264.277 179.062 264.569 178.504C264.861 177.941 265.268 177.504 265.788 177.195C266.308 176.885 266.909 176.731 267.593 176.731C268.315 176.731 268.927 176.9 269.43 177.24C269.938 177.575 270.314 178.05 270.559 178.665C270.804 179.279 270.896 180.004 270.836 180.837H269.295V180.27C269.291 179.514 269.157 178.961 268.895 178.613C268.633 178.265 268.22 178.091 267.657 178.091C267.021 178.091 266.548 178.289 266.239 178.684C265.929 179.075 265.775 179.649 265.775 180.406C265.775 181.11 265.929 181.656 266.239 182.043C266.548 182.43 267 182.623 267.593 182.623C267.975 182.623 268.304 182.54 268.579 182.372C268.859 182.2 269.074 181.953 269.224 181.63L270.758 182.095C270.492 182.722 270.079 183.21 269.521 183.558C268.966 183.906 268.354 184.08 267.683 184.08ZM265.285 180.837V179.664H270.075V180.837H265.285Z"
          fill="#374768"
        />
        <path
          d="M275.234 184.08C274.555 184.08 273.985 183.919 273.525 183.597C273.065 183.275 272.719 182.836 272.487 182.282C272.255 181.723 272.139 181.098 272.139 180.406C272.139 179.705 272.255 179.077 272.487 178.523C272.719 177.968 273.059 177.532 273.506 177.214C273.957 176.892 274.514 176.731 275.176 176.731C275.833 176.731 276.403 176.892 276.884 177.214C277.37 177.532 277.746 177.968 278.012 178.523C278.279 179.073 278.412 179.701 278.412 180.406C278.412 181.102 278.281 181.727 278.019 182.282C277.757 182.836 277.387 183.275 276.91 183.597C276.433 183.919 275.874 184.08 275.234 184.08ZM271.875 183.887V174.603H273.441V179H273.248V183.887H271.875ZM274.995 182.688C275.399 182.688 275.732 182.587 275.994 182.385C276.257 182.183 276.45 181.91 276.575 181.566C276.704 181.218 276.768 180.831 276.768 180.406C276.768 179.984 276.704 179.602 276.575 179.258C276.446 178.91 276.246 178.635 275.975 178.433C275.704 178.226 275.36 178.123 274.943 178.123C274.548 178.123 274.226 178.22 273.976 178.413C273.727 178.602 273.542 178.869 273.422 179.213C273.306 179.552 273.248 179.95 273.248 180.406C273.248 180.857 273.306 181.254 273.422 181.598C273.542 181.942 273.729 182.211 273.983 182.404C274.241 182.593 274.578 182.688 274.995 182.688Z"
          fill="#374768"
        />
        <path
          d="M279.446 183.887V176.924H280.82V178.62L280.652 178.4C280.738 178.168 280.852 177.958 280.994 177.769C281.14 177.575 281.314 177.416 281.516 177.292C281.688 177.175 281.877 177.085 282.083 177.021C282.294 176.952 282.509 176.911 282.728 176.898C282.947 176.881 283.16 176.89 283.366 176.924V178.375C283.16 178.314 282.921 178.295 282.651 178.317C282.384 178.338 282.143 178.413 281.929 178.542C281.714 178.658 281.537 178.807 281.4 178.987C281.267 179.168 281.168 179.374 281.103 179.606C281.039 179.834 281.007 180.081 281.007 180.347V183.887H279.446Z"
          fill="#374768"
        />
        <path
          d="M285.993 184.08C285.49 184.08 285.065 183.986 284.717 183.797C284.368 183.603 284.104 183.348 283.924 183.03C283.747 182.711 283.659 182.361 283.659 181.979C283.659 181.643 283.715 181.343 283.827 181.076C283.939 180.805 284.111 180.573 284.343 180.38C284.575 180.182 284.876 180.021 285.245 179.896C285.525 179.806 285.851 179.724 286.225 179.651C286.603 179.578 287.012 179.511 287.45 179.451C287.893 179.387 288.355 179.318 288.836 179.245L288.282 179.561C288.286 179.08 288.179 178.725 287.959 178.497C287.74 178.269 287.371 178.155 286.851 178.155C286.537 178.155 286.234 178.229 285.942 178.375C285.649 178.521 285.445 178.772 285.329 179.129L283.911 178.684C284.083 178.095 284.409 177.622 284.891 177.266C285.376 176.909 286.03 176.731 286.851 176.731C287.47 176.731 288.013 176.832 288.482 177.034C288.955 177.236 289.305 177.567 289.533 178.026C289.657 178.271 289.732 178.523 289.758 178.781C289.784 179.034 289.797 179.312 289.797 179.613V183.887H288.437V182.378L288.662 182.623C288.348 183.126 287.981 183.496 287.56 183.732C287.143 183.964 286.621 184.08 285.993 184.08ZM286.303 182.843C286.655 182.843 286.956 182.78 287.205 182.656C287.454 182.531 287.652 182.378 287.798 182.198C287.949 182.017 288.05 181.848 288.101 181.689C288.183 181.491 288.228 181.265 288.237 181.012C288.25 180.754 288.256 180.545 288.256 180.386L288.733 180.528C288.265 180.601 287.863 180.666 287.528 180.721C287.192 180.777 286.904 180.831 286.664 180.883C286.423 180.93 286.21 180.984 286.025 181.044C285.845 181.108 285.692 181.183 285.568 181.269C285.443 181.355 285.346 181.454 285.277 181.566C285.213 181.678 285.181 181.809 285.181 181.959C285.181 182.131 285.224 182.284 285.31 182.417C285.396 182.546 285.52 182.649 285.684 182.727C285.851 182.804 286.058 182.843 286.303 182.843Z"
          fill="#374768"
        />
        <path
          d="M295.17 183.887C294.71 183.973 294.258 184.009 293.816 183.997C293.377 183.988 292.984 183.908 292.636 183.758C292.288 183.603 292.023 183.36 291.843 183.03C291.684 182.729 291.6 182.421 291.591 182.108C291.583 181.794 291.579 181.439 291.579 181.044V174.99H293.126V180.954C293.126 181.233 293.128 181.478 293.132 181.689C293.141 181.899 293.186 182.071 293.268 182.204C293.422 182.462 293.67 182.606 294.009 182.636C294.349 182.666 294.736 182.649 295.17 182.585V183.887ZM290.315 178.143V176.924H295.17V178.143H290.315Z"
          fill="#374768"
        />
        <path
          d="M299.093 184.08C298.388 184.08 297.769 183.928 297.236 183.623C296.703 183.317 296.286 182.894 295.986 182.353C295.689 181.811 295.541 181.188 295.541 180.483C295.541 179.722 295.687 179.062 295.979 178.504C296.271 177.941 296.678 177.504 297.198 177.195C297.718 176.885 298.319 176.731 299.003 176.731C299.725 176.731 300.337 176.9 300.84 177.24C301.347 177.575 301.724 178.05 301.969 178.665C302.214 179.279 302.306 180.004 302.246 180.837H300.705V180.27C300.701 179.514 300.567 178.961 300.305 178.613C300.043 178.265 299.63 178.091 299.067 178.091C298.431 178.091 297.958 178.289 297.649 178.684C297.339 179.075 297.185 179.649 297.185 180.406C297.185 181.11 297.339 181.656 297.649 182.043C297.958 182.43 298.41 182.623 299.003 182.623C299.385 182.623 299.714 182.54 299.989 182.372C300.269 182.2 300.484 181.953 300.634 181.63L302.168 182.095C301.902 182.722 301.489 183.21 300.931 183.558C300.376 183.906 299.764 184.08 299.093 184.08ZM296.695 180.837V179.664H301.485V180.837H296.695Z"
          fill="#374768"
        />
        <rect x="186.752" y="270" width="142.611" height="6.791" fill="#EDEDED" />
        <rect x="186.752" y="287" width="142.611" height="6.791" fill="#EDEDED" />
        <rect x="186.752" y="304" width="142.611" height="6.791" fill="#EDEDED" />
        <rect x="186.752" y="321" width="142.611" height="6.791" fill="#EDEDED" />
        <path
          d="M311.758 387.511C276.75 362.571 226.055 383.64 186.684 310.433C149.945 242.116 117.145 248.635 117.145 248.635V387.341C117.145 402.482 129.523 414.7 144.663 414.503L359.074 411.704C359.074 411.704 346.748 412.468 311.741 387.511H311.758Z"
          fill="#4F95EF"
        />
        <mask
          id="mask0_199_8431"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="140"
          y="225"
          width="271"
          height="190"
        >
          <path
            d="M231.494 386.873C263.758 361.909 273.005 332.306 321.026 274.799C369.046 217.291 410.855 226.315 410.855 226.315V414.25H140.913C140.913 414.25 199.229 411.854 231.494 386.873Z"
            fill="url(#paint0_linear_199_8431)"
          />
        </mask>
        <g mask="url(#mask0_199_8431)">
          <path
            d="M231.494 386.873C263.758 361.909 273.005 332.306 321.026 274.799C369.046 217.291 410.855 226.315 410.855 226.315V387.086C410.855 402.089 398.693 414.25 383.691 414.25H140.913C140.913 414.25 199.229 411.854 231.494 386.873Z"
            fill="url(#paint1_linear_199_8431)"
          />
        </g>
        <rect x="186.752" y="219.01" width="142.611" height="6.791" fill="#EDEDED" />
        <rect x="186.752" y="235.987" width="142.611" height="6.791" fill="#EDEDED" />
        <rect x="186.752" y="252.965" width="142.611" height="6.791" fill="#EDEDED" />
        <rect x="86.585" y="155.627" width="9.05466" height="9.05466" fill="#2C3B58" />
        <rect x="95.6396" y="146.572" width="9.05466" height="9.05466" fill="#FFEE9E" />
        <rect x="104.694" y="137.518" width="9.05466" height="9.05466" fill="#2C3B58" />
        <rect x="140.913" y="80.3604" width="26.5981" height="26.5981" fill="#A9D2FF" />
        <rect x="167.512" y="53.7622" width="26.5981" height="26.5981" fill="#2C3B58" />
        <rect x="140.913" y="27.1641" width="26.5981" height="26.5981" fill="#2C3B58" />
        <rect x="424.438" y="144.309" width="13.582" height="13.582" fill="#2C3B58" />
        <rect x="424.438" y="117.145" width="13.582" height="13.582" fill="#AE8DF5" />
        <rect x="397.273" y="144.309" width="13.582" height="13.582" fill="#2C3B58" />
        <rect x="397.273" y="117.145" width="13.582" height="13.582" fill="#2C3B58" />
        <rect x="366.714" y="100.167" width="6.791" height="6.791" fill="#2C3B58" />
        <rect x="366.714" y="86.585" width="6.791" height="6.791" fill="#2C3B58" />
        <rect x="353.132" y="100.167" width="6.791" height="6.791" fill="#2C3B58" />
        <rect x="353.132" y="86.585" width="6.791" height="6.791" fill="#2C3B58" />
      </g>
      <defs>
        <filter
          id="filter0_f_199_8431"
          x="-3.3953"
          y="325.968"
          width="541.582"
          height="185.055"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="37.3505" result="effect1_foregroundBlur_199_8431" />
        </filter>
        <linearGradient
          id="paint0_linear_199_8431"
          x1="526.612"
          y1="-152.257"
          x2="354.962"
          y2="351.148"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEE9E" stopOpacity="0" />
          <stop offset="1" stopColor="#F384A5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_199_8431"
          x1="264.524"
          y1="413.639"
          x2="335.49"
          y2="191.648"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEE9E" />
          <stop offset="0.48" stopColor="#F384A5" />
          <stop offset="1" stopColor="#A08DC2" />
        </linearGradient>
        <clipPath id="clip0_199_8431">
          <rect width="528" height="476" rx="10.1865" fill="white" />
        </clipPath>
        <clipPath id="clip1_199_8431">
          <rect x="225.732" y="173.103" width="14.5582" height="14.5582" rx="1.66379" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
