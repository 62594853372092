import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';

import { MdBox } from '../override-components/MdBox';
import { MdTypography } from '../override-components/MdTypography';

type Props = {
  icon: React.ReactNode;
  title: string;
  route: Array<string>;
};

export function Breadcrumbs({ icon, title, route }: Props) {
  const { t } = useTranslation();

  const routes = route.slice(0, -1);

  return (
    <MdBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={({ palette: { grey } }) => ({
          '& .MuiBreadcrumbs-separator': {
            color: grey[600],
          },
        })}
      >
        <Tooltip title={t('Go to home page')}>
          <Link to="/">
            <MdTypography component="span" variant="body2" color="dark" opacity={0.5} sx={{ lineHeight: 0 }}>
              <Icon>{icon}</Icon>
            </MdTypography>
          </Link>
        </Tooltip>
        {routes.map((el) => (
          <Link to={`/${el}`} key={el}>
            <MdTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color="dark"
              opacity={0.5}
              sx={{ lineHeight: 0 }}
            >
              {el}
            </MdTypography>
          </Link>
        ))}
        <MdTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color="dark"
          sx={{ lineHeight: 0 }}
        >
          {title.replace('-', ' ')}
        </MdTypography>
      </MuiBreadcrumbs>
    </MdBox>
  );
}
