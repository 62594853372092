import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import MuiDialog, { DialogProps as MdDialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import styled from '@mui/material/styles/styled';
import { Breakpoint, SxProps } from '@mui/system';

import { LoadingBackdrop } from '../components/LoadingBackdrop';
// import { MdScrollbar } from '../components/MdScrollBar/MdScrollBar';

type DialogTitleProps = { id: string; children?: React.ReactNode; onClose?: () => void };

type DialogProps = MdDialogProps & {
  title: string;
  open: boolean;
  maxWidth?: Breakpoint;
  buttonText?: string;
  loading?: boolean;
  onClose?: () => void;
  actions?: React.ReactNode;
  children?: React.ReactNode;
  contentSx?: SxProps;
};

const BootstrapDialog = styled(MuiDialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export function Dialog(props: DialogProps) {
  const {
    title,
    buttonText = 'Close',
    open,
    onClose,
    actions,
    children,
    loading = false,
    maxWidth,
    contentSx,
    ...otherProps
  } = props;

  return (
    <BootstrapDialog fullWidth open={open} maxWidth={maxWidth} onClose={onClose} {...otherProps}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {title}
      </BootstrapDialogTitle>
      <DialogContent dividers sx={contentSx}>
        {children}
      </DialogContent>
      <DialogActions>
        {actions || (
          <Button autoFocus onClick={onClose}>
            {buttonText}
          </Button>
        )}
      </DialogActions>
      <LoadingBackdrop loading={loading} />
    </BootstrapDialog>
  );
}
