import { Dispatch } from 'react';
import { entries, flow, reduce } from 'lodash/fp';

import { ActionCreator } from './createAction';
import { hoistNonReactStatics } from './hoistStatics';
import { Action } from './useReducer';

export type BoundActionCreators<Type> = { [Property in keyof Type]: ActionCreator<any> };

function bindActionCreator(actionCreator: ActionCreator<any>, dispatch: Dispatch<Action<any>>): ActionCreator<any> {
  return hoistNonReactStatics(function () {
    // @ts-ignore
    // eslint-disable-next-line prefer-rest-params
    return dispatch(Reflect.apply(actionCreator, this, arguments));
  }, actionCreator) as unknown as ActionCreator<any>;
}

export function bindActionCreators<AC extends Record<string, ActionCreator<any>>>(
  actionCreators: AC,
  dispatch: (action: Action<any>) => void,
): BoundActionCreators<AC> {
  return flow(
    entries,
    reduce<[string, ActionCreator<any>], BoundActionCreators<AC>>(
      (acc, [name, actionCreator]) => ({ ...acc, [name]: bindActionCreator(actionCreator, dispatch) }),
      {} as BoundActionCreators<AC>,
    ),
  )(actionCreators);
}
