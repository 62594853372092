import React from 'react';
import { SnackbarProvider } from 'notistack';

import { ErrorBoundary, snackbarContent } from '@core/web/root';
import { ThemeProvider } from '@core/web/theme';

import { Store } from './store/Store';
import { Router } from './Router';

export function App() {
  const notistackRef = React.useRef<SnackbarProvider>(null);

  return (
    <ErrorBoundary>
      <ThemeProvider>
        <SnackbarProvider ref={notistackRef} autoHideDuration={10_000} content={snackbarContent(notistackRef)}>
          <Store>
            <Router />
          </Store>
        </SnackbarProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
}
