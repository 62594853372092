import React, { useCallback, useRef, useState } from 'react';

import { CreditCard } from './components/card';
import { CForm } from './components/form';

import './style.scss';

const initialState = {
  cardNumber: '**** **** **** ****',
  cardHolder: 'FULL NAME',
  cardMonth: '',
  cardYear: '',
  cardCvv: '',
  isCardFlipped: false,
};

export function PaymentCardForm({ card = {} }) {
  const [state, setState] = useState({ ...initialState, ...card });
  const [currentFocusedElm, setCurrentFocusedElm] = useState(null);

  const updateStateValues = useCallback(
    (keyName: string, value: any) => {
      setState({
        ...state,
        // @ts-expect-error: noop
        [keyName]: value || initialState[keyName],
      });
    },
    [state],
  );

  // React.useEffect(() => {
  //   setState({ ...initialState, ...card });
  // }, [card]);

  // References for the Form Inputs used to focus corresponding inputs.
  const formFieldsRefObj = {
    cardNumber: useRef(),
    cardHolder: useRef(),
    cardDate: useRef(),
  };

  const focusFormFieldByKey = (key: string | number) => {
    // @ts-expect-error: noop
    formFieldsRefObj[key].current.focus();
  };

  // This are the references for the Card DIV elements.
  const cardElementsRef = {
    cardNumber: useRef(),
    cardHolder: useRef(),
    cardDate: useRef(),
    cardCvv: useRef(),
  };

  const onCardFormInputFocus = (_event: any, inputName: string | number) => {
    // @ts-expect-error: noop
    const refByName = cardElementsRef[inputName];
    console.log(refByName);
    setCurrentFocusedElm(refByName);
  };

  const onCardInputBlur = useCallback(() => {
    setCurrentFocusedElm(null);
  }, []);

  return (
    <CForm
      checked={Object.keys(card).length === 0}
      onUpdateState={updateStateValues}
      // @ts-expect-error: noop
      cardNumberRef={formFieldsRefObj.cardNumber}
      // cardHolderRef={formFieldsRefObj.cardHolder}
      // @ts-expect-error: noop
      cardDateRef={formFieldsRefObj.cardDate}
      onCardInputFocus={onCardFormInputFocus}
      onCardInputBlur={onCardInputBlur}
    >
      <CreditCard
        cardNumber={state.cardNumber}
        cardHolder={state.cardHolder}
        cardMonth={state.cardMonth}
        cardYear={state.cardYear}
        isCardFlipped={state.isCardFlipped}
        // @ts-expect-error: noop
        currentFocusedElm={currentFocusedElm}
        onCardElementClick={focusFormFieldByKey}
        // @ts-expect-error: noop
        cardNumberRef={cardElementsRef.cardNumber}
        // @ts-expect-error: noop
        cardHolderRef={cardElementsRef.cardHolder}
        // @ts-expect-error: noop
        cardDateRef={cardElementsRef.cardDate}
        // @ts-expect-error: noop
        cardCvvRef={cardElementsRef.cardCvv}
      />
    </CForm>
  );
}
