import React from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { Variant } from '@mui/material/styles/createTypography';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/system';

type Props = {
  src: string;
  alt: string;
  brandName?: string;
  withText?: boolean;
  textVariant?: Variant;
  containerSx?: SxProps<Theme>;
  imageSx?: SxProps<Theme>;
  textSx?: SxProps<Theme>;
};

export function Logo(props: Props) {
  const { src, alt, brandName, withText, textVariant = 'h3', containerSx, imageSx, textSx } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...containerSx }}>
      <Box component="img" src={src} alt={withText ? '' : alt} sx={{ m: 1, width: '60px', ...imageSx }} />
      {withText && brandName && (
        <Typography noWrap component="div" variant={textVariant} sx={{ lineHeight: 1.3, ...textSx }}>
          {brandName}
        </Typography>
      )}
    </Box>
  );
}
