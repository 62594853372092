import React from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import {
  AuBankAccountElement,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  FpxBankElement,
  IbanElement,
  IdealBankElement,
} from '@stripe/react-stripe-js';

import { StripeInput } from './StripeInput';

type StripeElement =
  | typeof AuBankAccountElement
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement
  | typeof FpxBankElement
  | typeof IbanElement
  | typeof IdealBankElement;

interface StripeTextFieldProps<T extends StripeElement>
  extends Omit<TextFieldProps, 'onChange' | 'inputComponent' | 'inputProps'> {
  inputProps?: React.ComponentProps<T>;
  labelErrorMessage?: string;
  onChange?: React.ComponentProps<T>['onChange'];
  stripeElement?: T;
}

function StripeTextFieldBase<T extends StripeElement>(
  props: StripeTextFieldProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>,
) {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    labelErrorMessage,
    stripeElement,
    ...other
  } = props;

  return (
    <TextField
      fullWidth
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      error={error}
      InputProps={{
        ...InputProps,
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
        },
        inputComponent: StripeInput,
      }}
      helperText={error ? labelErrorMessage : helperText}
      {...(other as any)}
      ref={ref}
    />
  );
}

export const StripeTextField = React.forwardRef(StripeTextFieldBase);

export const StripeTextFieldNumber = React.forwardRef<HTMLDivElement, StripeTextFieldProps<typeof CardNumberElement>>(
  (props, ref) => <StripeTextField ref={ref} label="Credit Card Number" stripeElement={CardNumberElement} {...props} />,
);

export const StripeTextFieldExpiry = React.forwardRef<HTMLDivElement, StripeTextFieldProps<typeof CardExpiryElement>>(
  (props, ref) => <StripeTextField ref={ref} label="Expiration Date" stripeElement={CardExpiryElement} {...props} />,
);

export const StripeTextFieldCVC = React.forwardRef<HTMLDivElement, StripeTextFieldProps<typeof CardCvcElement>>(
  (props, ref) => <StripeTextField ref={ref} label="CVC Code" stripeElement={CardCvcElement} {...props} />,
);
