import React from 'react';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import { MdTypography } from '@core/web/look';

export function ChipLabel({ title, text, active }: { title: string; text: string; active?: boolean }) {
  return (
    <Chip
      variant={active ? 'outlined' : 'filled'}
      label={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={2}
          divider={
            active && (
              <Divider
                orientation="vertical"
                flexItem
                sx={{ borderColor: '#374768', alignSelf: 'initial', height: '16px' }}
              />
            )
          }
        >
          <MdTypography variant="body2">{title}</MdTypography>
          {active && <MdTypography variant="body2">{text}</MdTypography>}
        </Stack>
      }
      sx={{ borderRadius: '4px', padding: '8px 0' }}
    />
  );
}
