import getOr from 'lodash/fp/getOr';

type InputValue = Record<string, any> | null | undefined;
type MaybeValue<T> = T | null;
export type Selector<T> = (obj: InputValue) => MaybeValue<T>;

// @ts-expect-error: noop
export const createSelector = (path: keyof TObject) => getOr(null)(path);
export const createSelectorWithFallbackPath =
  <T>(fallbackPath: Array<string>, path: Array<string>): Selector<T> =>
  (obj: InputValue) => {
    // @ts-expect-error: noop
    const firstTry = getOr<MaybeValue<T>>(null)(path)(obj);
    let secondTry = null;

    if (firstTry) {
      // @ts-expect-error: noop
      secondTry = getOr<MaybeValue<T>>(null)(fallbackPath)(obj);
    }
    return firstTry || secondTry;
  };
