import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

import { SxFn } from '../types';

export function mergeSx(sxFn: SxFn, partialSxOrSxFn: SystemStyleObject<Theme> | SxFn): SxFn {
  return (theme: Theme) => ({
    ...(typeof sxFn === 'function' ? sxFn(theme) : {}),
    ...(typeof partialSxOrSxFn === 'function' ? partialSxOrSxFn(theme) : partialSxOrSxFn),
  });
}
