import React, { FormEvent } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { SxProps } from '@mui/system';

import { Form } from '../material/Form';

const noop = () => undefined;

type Props = {
  name?: string;
  onSubmit?: (event?: FormEvent<HTMLFormElement> | undefined) => void;
  actions?: React.ReactNode;
  cardSx?: SxProps;
  cardContentSx?: SxProps;
};

export const CardWithForm = React.forwardRef<HTMLFormElement, React.PropsWithChildren<Props>>((props, ref) => {
  const { name, onSubmit = noop, actions, cardSx, cardContentSx, children } = props;

  return (
    <Card
      sx={{
        display: 'flex',
        padding: 0,
        backgroundColor: '#192337',
        borderRadius: '8px',
        minHeight: 'calc(100% - 82px)',
        ...cardSx,
      }}
    >
      <Form ref={ref} name={name} onSubmit={onSubmit} sx={{ display: 'flex', width: '100%', flex: 1 }}>
        <CardContent component={Stack} sx={{ flex: 1, padding: '22px', ...cardContentSx }}>
          {children}
        </CardContent>
        {actions || null}
      </Form>
    </Card>
  );
});
