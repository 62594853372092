import React from 'react';
import { Case, Default, Switch } from 'react-if';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import Zoom from '@mui/material/Zoom';

enum TypeEnum {
  GROW = 'grow',
  FADE = 'fade',
  COLLAPSE = 'collapse',
  SLIDE = 'slide',
  ZOOM = 'zoom',
}

enum PositionEnum {
  TOP_LEFT = 'top-left',
  TOP_RIGHT = 'top-right',
  TOP = 'top',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM = 'bottom',
}

enum DirectionEnum {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
}

type Props = TransitionProps & {
  type?: TypeEnum;
  position?: PositionEnum;
  direction?: DirectionEnum;
};

export const Transitions = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((props, ref) => {
  const {
    children,
    position = PositionEnum.TOP_LEFT,
    type = TypeEnum.GROW,
    direction = DirectionEnum.UP,
    ...others
  } = props;

  let positionSX = { transformOrigin: '0 0 0' };

  switch (position) {
    case PositionEnum.TOP_RIGHT: {
      positionSX = { transformOrigin: 'top right' };
      break;
    }
    case PositionEnum.TOP: {
      positionSX = { transformOrigin: 'top' };
      break;
    }
    case PositionEnum.BOTTOM_LEFT: {
      positionSX = { transformOrigin: 'bottom left' };
      break;
    }
    case PositionEnum.BOTTOM_RIGHT: {
      positionSX = { transformOrigin: 'bottom right' };
      break;
    }
    case PositionEnum.BOTTOM: {
      positionSX = { transformOrigin: 'bottom' };
      break;
    }
    case PositionEnum.TOP_LEFT:
    default: {
      positionSX = { transformOrigin: '0 0 0' };
      break;
    }
  }

  return (
    <Box ref={ref}>
      <Switch>
        <Case condition={type === TypeEnum.GROW}>
          <Grow {...others}>
            <Box sx={positionSX}>{children}</Box>
          </Grow>
        </Case>
        <Case condition={type === TypeEnum.COLLAPSE}>
          <Collapse {...others} sx={positionSX}>
            {children}
          </Collapse>
        </Case>
        <Case condition={type === TypeEnum.FADE}>
          <Fade {...others} timeout={{ appear: 500, enter: 600, exit: 400 }}>
            <Box sx={positionSX}>{children}</Box>
          </Fade>
        </Case>
        <Case condition={type === TypeEnum.SLIDE}>
          <Slide {...others} timeout={{ appear: 0, enter: 400, exit: 200 }} direction={direction}>
            <Box sx={positionSX}>{children}</Box>
          </Slide>
        </Case>
        <Case condition={type === TypeEnum.ZOOM}>
          <Zoom {...others}>
            <Box sx={positionSX}>{children}</Box>
          </Zoom>
        </Case>
        <Default>{children}</Default>
      </Switch>
    </Box>
  );
});
