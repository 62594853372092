interface IKNOWN_STATICS {
  name: true;
  length: true;
  prototype: true;
  caller: true;
  callee: true;
  arguments: true;
  arity: true;
}

export type NonReactStatics<
  S extends (...args: any) => any,
  C extends { [key: string]: true } = Record<string, any>,
> = {
  [key in Exclude<keyof S, keyof IKNOWN_STATICS | keyof C>]: S[key];
};

const KNOWN_STATICS = {
  name: true,
  length: true,
  prototype: true,
  caller: true,
  callee: true,
  arguments: true,
  arity: true,
};

const { defineProperty } = Object;
const { getOwnPropertyNames } = Object;
const { getOwnPropertySymbols } = Object;
const { getOwnPropertyDescriptor } = Object;
const { getPrototypeOf } = Object;
const objectPrototype = Object.prototype;

export function hoistNonReactStatics<
  T extends (...args: any) => any,
  S extends (...args: any) => any,
  C extends { [key: string]: true } = Record<string, any>,
>(targetFunction: T, sourceFunction: S, excludeList?: C) {
  // don't hoist over string (html) components
  if (objectPrototype) {
    const inheritedComponent = getPrototypeOf(sourceFunction);
    if (inheritedComponent && inheritedComponent !== objectPrototype) {
      hoistNonReactStatics(targetFunction, inheritedComponent, excludeList);
    }
  }

  let symbolKeys: symbol[] = [];
  const propertyKeys: string[] = getOwnPropertyNames(sourceFunction);

  if (getOwnPropertySymbols) {
    symbolKeys = getOwnPropertySymbols(sourceFunction);
  }

  const allKeys: (string | symbol)[] = [...propertyKeys, ...symbolKeys];

  // eslint-disable-next-line no-plusplus
  for (const key of allKeys) {
    if (!KNOWN_STATICS[key] && !(excludeList && typeof key !== 'symbol' && excludeList[key])) {
      const descriptor = getOwnPropertyDescriptor(sourceFunction, key);
      try {
        if (descriptor) {
          // Avoid failures from read-only properties
          defineProperty(targetFunction, key, descriptor);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  return targetFunction;
}
