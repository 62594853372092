import React from 'react';
import MuiCard, { CardProps } from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent, { CardContentProps } from '@mui/material/CardContent';
import Stack, { StackProps } from '@mui/material/Stack';
import { SxProps } from '@mui/system';

type Props = {
  cardProps?: CardProps;
  cardSx?: SxProps;
  cardContentProps?: CardContentProps & StackProps;
  cardContentSx?: SxProps;
  actions?: React.ReactNode;
};

export function SimpleCard(props: React.PropsWithChildren<Props>) {
  const { cardProps, cardSx, cardContentProps, cardContentSx, actions, children } = props;

  return (
    <MuiCard
      {...cardProps}
      sx={{
        position: 'relative',
        padding: { sm: 2, md: 3 },
        backgroundColor: '#0f1535',
        borderRadius: '8px',
        ...cardSx,
      }}
    >
      <CardContent
        component={Stack}
        {...cardContentProps}
        sx={{
          flex: 1,
          padding: 0,
          '&:last-child': {
            paddingBottom: 0,
          },
          ...cardContentSx,
        }}
      >
        {children}
      </CardContent>
      {actions || null}
    </MuiCard>
  );
}
