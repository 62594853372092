import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';

type AvailableColors =
  | 'transparent'
  | 'white'
  | 'black'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'light'
  | 'dark'
  | 'text'
  | 'grey-100'
  | 'grey-200'
  | 'grey-300'
  | 'grey-400'
  | 'grey-500'
  | 'grey-600'
  | 'grey-700'
  | 'grey-800'
  | 'grey-900';

type OwnerState = {
  color: AvailableColors;
  bgColor: AvailableColors;
  opacity: number;
  borderRadius: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'section' | 'none';
  variant: 'contained' | 'gradient';
  shadow: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'inset' | 'none';
};

export const MdBoxRoot = styled(Box)<{ ownerState: OwnerState }>(({ theme, ownerState }) => {
  const { palette, functions, borders, boxShadows } = theme;
  const { variant, bgColor, color, opacity, borderRadius, shadow } = ownerState;

  const { linearGradient } = functions;

  const greyColors = {
    'grey-100': palette.grey[100],
    'grey-200': palette.grey[200],
    'grey-300': palette.grey[300],
    'grey-400': palette.grey[400],
    'grey-500': palette.grey[500],
    'grey-600': palette.grey[600],
    'grey-700': palette.grey[700],
    'grey-800': palette.grey[800],
    'grey-900': palette.grey[900],
  };

  const validGradients = ['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark', 'light'];

  const validColors = new Set([
    'transparent',
    'white',
    'black',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
    'text',
    'grey-100',
    'grey-200',
    'grey-300',
    'grey-400',
    'grey-500',
    'grey-600',
    'grey-700',
    'grey-800',
    'grey-900',
  ]);

  const validBorderRadius = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'section'];
  const validBoxShadows = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl', 'inset'];

  let backgroundValue = bgColor;

  if (variant === 'gradient') {
    backgroundValue = validGradients.includes(bgColor)
      ? // @ts-expect-error: noop
        linearGradient(palette.gradients[bgColor].main, palette.gradients[bgColor].state)
      : palette.white.main;
  } else if (validColors.has(bgColor)) {
    // @ts-expect-error: noop
    backgroundValue = palette[bgColor] ? palette[bgColor].main : greyColors[bgColor];
  }

  let colorValue = color;

  if (validColors.has(color)) {
    // @ts-expect-error: noop
    colorValue = palette[color] ? palette[color].main : greyColors[color];
  }

  let borderRadiusValue = borderRadius;

  if (validBorderRadius.includes(borderRadius)) {
    // @ts-expect-error: noop
    borderRadiusValue = borders.borderRadius[borderRadius];
  }

  let boxShadowValue = 'none';

  if (validBoxShadows.includes(shadow)) {
    // @ts-expect-error: noop
    boxShadowValue = boxShadows[shadow];
  }

  return {
    opacity,
    background: backgroundValue,
    color: colorValue,
    borderRadius: borderRadiusValue,
    boxShadow: boxShadowValue,
  };
});
