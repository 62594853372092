import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function LinkIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7753 2.75068C13.907 1.65773 15.4226 1.05296 16.9958 1.06663C18.5689 1.0803 20.0738 1.71132 21.1863 2.82377C22.2987 3.93621 22.9297 5.44109 22.9434 7.01427C22.9571 8.58745 22.3523 10.1031 21.2593 11.2347L21.2472 11.2471L18.2473 14.2469C17.639 14.8554 16.907 15.326 16.1009 15.6267C15.2948 15.9274 14.4334 16.0512 13.5753 15.9897C12.7171 15.9283 11.8822 15.683 11.1272 15.2705C10.3721 14.858 9.71464 14.2879 9.19929 13.5989C8.86849 13.1567 8.95883 12.53 9.40108 12.1992C9.84333 11.8684 10.47 11.9587 10.8008 12.401C11.1444 12.8603 11.5827 13.2403 12.0861 13.5153C12.5894 13.7903 13.146 13.9539 13.7181 13.9949C14.2903 14.0358 14.8645 13.9533 15.4019 13.7528C15.9393 13.5523 16.4273 13.2386 16.8328 12.833L16.833 12.8329L19.8265 9.83934C20.5515 9.08557 20.9526 8.0777 20.9435 7.03165C20.9344 5.98286 20.5137 4.97961 19.772 4.23798C19.0304 3.49635 18.0272 3.07567 16.9784 3.06656C15.9319 3.05746 14.9236 3.45887 14.1697 4.1845L12.4551 5.88913C12.0634 6.27851 11.4303 6.27667 11.0409 5.88501C10.6515 5.49335 10.6534 4.86018 11.045 4.4708L12.765 2.7608L12.7753 2.75068Z"
        // fill="#DCCAFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.89911 8.37351C8.70522 8.07281 9.56657 7.94899 10.4247 8.01045C11.2829 8.07191 12.1178 8.31721 12.8729 8.72972C13.6279 9.14223 14.2854 9.71229 14.8007 10.4012C15.1315 10.8435 15.0412 11.4702 14.5989 11.801C14.1567 12.1318 13.53 12.0415 13.1992 11.5992C12.8556 11.1399 12.4173 10.7599 11.9139 10.4849C11.4106 10.2098 10.854 10.0463 10.2819 10.0053C9.70976 9.96436 9.13552 10.0469 8.59811 10.2474C8.06071 10.4478 7.5727 10.7615 7.16719 11.1672L4.17355 14.1608C3.44851 14.9146 3.04746 15.9225 3.05655 16.9685C3.06566 18.0173 3.48634 19.0206 4.22797 19.7622C4.9696 20.5038 5.97285 20.9245 7.02164 20.9336C8.06768 20.9427 9.07555 20.5417 9.82932 19.8166L11.5328 18.1131C11.9234 17.7226 12.5565 17.7226 12.9471 18.1131C13.3376 18.5036 13.3376 19.1368 12.9471 19.5273L11.2371 21.2373L11.2247 21.2495C10.0931 22.3425 8.57744 22.9472 7.00426 22.9336C5.43108 22.9199 3.9262 22.2889 2.81376 21.1764C1.70131 20.064 1.07029 18.5591 1.05662 16.9859C1.04295 15.4127 1.64772 13.8971 2.74067 12.7655L2.75285 12.7531L5.75272 9.75325C5.75276 9.7532 5.75268 9.75329 5.75272 9.75325C6.36096 9.14482 7.09305 8.67419 7.89911 8.37351Z"
        // fill="#DCCAFF"
      />
    </SvgIcon>
  );
}
