Object.defineProperty(exports, '__esModule', { value: true });

const tslib = require('tslib');

const genericMessage = 'Invariant Violation';
const _a = Object.setPrototypeOf;
const setPrototypeOf =
  _a === void 0
    ? function (obj, proto) {
        obj.__proto__ = proto;
        return obj;
      }
    : _a;
const InvariantError = /** @class */ (function (_super) {
  tslib.__extends(InvariantError, _super);
  function InvariantError(message) {
    if (message === void 0) {
      message = genericMessage;
    }
    const _this =
      _super.call(
        this,
        typeof message === 'number'
          ? `${genericMessage}: ${message} (see https://github.com/apollographql/invariant-packages)`
          : message,
      ) || this;
    _this.framesToPop = 1;
    _this.name = genericMessage;
    setPrototypeOf(_this, InvariantError.prototype);
    return _this;
  }
  return InvariantError;
})(Error);
function invariant(condition, message) {
  if (!condition) {
    throw new InvariantError(message);
  }
}
const verbosityLevels = ['debug', 'log', 'warn', 'error', 'silent'];
let verbosityLevel = verbosityLevels.indexOf('log');
function wrapConsoleMethod(name) {
  return function () {
    if (verbosityLevels.indexOf(name) >= verbosityLevel) {
      // Default to console.log if this host environment happens not to provide
      // all the console.* methods we need.
      const method = console[name] || console.log;
      return method.apply(console, arguments);
    }
  };
}
(function (invariant) {
  invariant.debug = wrapConsoleMethod('debug');
  invariant.log = wrapConsoleMethod('log');
  invariant.warn = wrapConsoleMethod('warn');
  invariant.error = wrapConsoleMethod('error');
})(invariant || (invariant = {}));
function setVerbosity(level) {
  const old = verbosityLevels[verbosityLevel];
  verbosityLevel = Math.max(0, verbosityLevels.indexOf(level));
  return old;
}
const invariant$1 = invariant;

exports.InvariantError = InvariantError;
exports.default = invariant$1;
exports.invariant = invariant;
exports.setVerbosity = setVerbosity;
