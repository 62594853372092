/* eslint-disable no-param-reassign */
import uniqBy from 'lodash/uniqBy';

import { Action, bindActionCreators, createSlice, dispatch, ReducerCase, ReducerCases } from '@wpa/redux-utils';

import { Resource, State } from './types';

export const initialState = () => ({
  uploadedResources: [],
  userEmail: null,
});

type Cases<S> = {
  setUserEmail: ReducerCase<S, Action<string>>;
  setOneResource: ReducerCase<S, Action<Resource>>;
  setManyResources: ReducerCase<S, Action<Array<Resource>>>;
  removeOneResource: ReducerCase<S, Action<number>>;
};

const { actions, reducer } = createSlice<State, ReducerCases<State, Action<any>, Cases<State>>>({
  name: '@app',
  withActionPrefix: false,
  reducerCases: {
    setUserEmail: (draft, { payload }) => {
      draft.userEmail = payload;
    },
    setOneResource: (draft, { payload }) => {
      draft.uploadedResources = uniqBy([...draft.uploadedResources, payload], 'name');
    },
    setManyResources: (draft, { payload }) => {
      draft.uploadedResources = uniqBy([...draft.uploadedResources, ...payload], 'name');
    },
    removeOneResource: (draft, { payload }) => {
      draft.uploadedResources = draft.uploadedResources.filter((_, idx) => idx !== payload);
    },
  },
});

const { setOnboardingStep, setOneResource, setManyResources, removeOneResource, setUserEmail } = bindActionCreators<
  typeof actions
>(actions, dispatch);

export { removeOneResource, reducer as rootReducer, setManyResources, setOnboardingStep, setOneResource, setUserEmail };
