import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Theme } from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import { SnackbarContent, SnackbarProvider, useSnackbar } from 'notistack';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => (
  <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
));

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
}));

type Props = {
  id: string | number;
  message: string | React.ReactNode | [];
  snackbarProviderRef: React.RefObject<SnackbarProvider>;
};

export const SnackMessage = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { id, message, snackbarProviderRef } = props;

  const classes = useStyles();
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = React.useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  const isElement = React.isValidElement(message) || typeof message === 'string';

  let variant: AlertColor = 'info';

  try {
    // @ts-expect-error: noop
    const snack = snackbarProviderRef.current.state?.snacks?.find((item) => item.key === id);

    if (snack) {
      variant = snack.variant;
    }
  } catch (error) {
    console.error(error);
  }

  return (
    <SnackbarContent ref={ref} className={classes.root}>
      <Alert
        severity={variant}
        action={
          <IconButton color="inherit" size="small" onClick={handleDismiss} sx={{ paddingTop: '3px' }}>
            <CloseIcon />
          </IconButton>
        }
        sx={{ width: '100%' }}
      >
        {/* @ts-expect-error: noop */}
        <Typography variant="subtitle2">{isElement ? message : message?.[0]}</Typography>
        {!isElement &&
          (Array.isArray(message) ? message : [])
            .filter((_, idx) => idx > 0)
            .map((text, i) => (
              <Typography key={`${i + 1}${text}`} variant="subtitle2">
                {text}
              </Typography>
            ))}
      </Alert>
    </SnackbarContent>
  );
});
