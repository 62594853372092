import { ThemeConfig, TypographyConfig } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';
import { Property } from 'csstype';

import { pxToRem } from '../functions';

export const getTypography = (colors: ThemeConfig['colors']): TypographyOptions & TypographyConfig => {
  const { dark, text } = colors;

  const baseProperties = {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSizeXXS: pxToRem(10.4),
    fontSizeXS: pxToRem(12),
    fontSizeSM: pxToRem(14),
    fontSizeRegular: pxToRem(16),
    fontSizeLG: pxToRem(18),
    fontSizeXL: pxToRem(20),
  };

  const baseHeadingProperties = {
    color: text.primary,
    fontFamily: baseProperties.fontFamily,
    fontWeight: baseProperties.fontWeightMedium,
  };

  const baseDisplayProperties = {
    color: dark.main,
    fontFamily: baseProperties.fontFamily,
    fontWeight: baseProperties.fontWeightLight,
    lineHeight: 1.2,
  };

  return {
    fontFamily: baseProperties.fontFamily,
    fontWeightLight: baseProperties.fontWeightLight,
    fontWeightRegular: baseProperties.fontWeightRegular,
    fontWeightMedium: baseProperties.fontWeightMedium,
    fontWeightBold: baseProperties.fontWeightBold,
    h1: {
      fontSize: pxToRem(48),
      lineHeight: 1.25,
      ...baseHeadingProperties,
    },
    h2: {
      fontSize: pxToRem(36),
      lineHeight: 1.3,
      ...baseHeadingProperties,
    },
    h3: {
      fontSize: pxToRem(30),
      lineHeight: 1.375,
      ...baseHeadingProperties,
    },
    h4: {
      fontSize: pxToRem(24),
      lineHeight: 1.375,
      ...baseHeadingProperties,
    },
    h5: {
      fontSize: pxToRem(20),
      lineHeight: 1.375,
      ...baseHeadingProperties,
    },
    h6: {
      fontSize: pxToRem(16),
      lineHeight: 1.625,
      ...baseHeadingProperties,
    },
    lg: {
      fontSize: pxToRem(18),
      lineHeight: 1.625,
      ...baseHeadingProperties,
    },
    xxs: {
      fontSize: pxToRem(10),
      lineHeight: 1.625,
      ...baseHeadingProperties,
    },
    subtitle1: {
      fontFamily: baseProperties.fontFamily,
      fontSize: baseProperties.fontSizeXL,
      fontWeight: baseProperties.fontWeightRegular,
      lineHeight: 1.625,
      color: text.primary,
    },
    subtitle2: {
      fontFamily: baseProperties.fontFamily,
      fontSize: baseProperties.fontSizeRegular,
      fontWeight: baseProperties.fontWeightMedium,
      lineHeight: 1.6,
      color: text.primary,
    },
    body1: {
      fontFamily: baseProperties.fontFamily,
      fontSize: baseProperties.fontSizeXL,
      fontWeight: baseProperties.fontWeightRegular,
      lineHeight: 1.625,
      color: text.primary,
    },
    body2: {
      fontFamily: baseProperties.fontFamily,
      fontSize: baseProperties.fontSizeRegular,
      fontWeight: baseProperties.fontWeightRegular,
      lineHeight: 1.6,
      color: text.primary,
    },
    button: {
      fontFamily: baseProperties.fontFamily,
      fontSize: baseProperties.fontSizeSM,
      fontWeight: baseProperties.fontWeightBold,
      lineHeight: 1.5,
      textTransform: 'uppercase' as Property.TextTransform,
    },
    caption: {
      fontFamily: baseProperties.fontFamily,
      fontSize: baseProperties.fontSizeXS,
      fontWeight: baseProperties.fontWeightRegular,
      lineHeight: 1.25,
    },
    overline: {
      fontFamily: baseProperties.fontFamily,
    },
    d1: {
      fontSize: pxToRem(80),
      ...baseDisplayProperties,
    },
    d2: {
      fontSize: pxToRem(72),
      ...baseDisplayProperties,
    },
    d3: {
      fontSize: pxToRem(64),
      ...baseDisplayProperties,
    },
    d4: {
      fontSize: pxToRem(56),
      ...baseDisplayProperties,
    },
    d5: {
      fontSize: pxToRem(48),
      ...baseDisplayProperties,
    },
    d6: {
      fontSize: pxToRem(40),
      ...baseDisplayProperties,
    },
    size: {
      xxs: baseProperties.fontSizeXXS,
      xs: baseProperties.fontSizeXS,
      sm: baseProperties.fontSizeSM,
      md: baseProperties.fontSizeRegular,
      regular: baseProperties.fontSizeRegular,
      lg: baseProperties.fontSizeLG,
      xl: baseProperties.fontSizeXL,
    },
    lineHeight: {
      sm: 1.25,
      regular: 1.5,
      lg: 2,
    },
  };
};
