import React from 'react';
import { DropzoneOptions } from 'react-dropzone';
import { SxProps, Theme } from '@mui/material';

import { LoadingBackdrop } from '../LoadingBackdrop';

import { FileRenderError, useFileReader } from './hooks/useFileReader';
import { BaseUploader } from './BaseUploader';
import { FileReaderMethods } from './file-reader-method.enum';

type Props = DropzoneOptions & {
  method?: FileReaderMethods;
  sx?: SxProps<Theme>;
  errorsTKeys?: { defaultError: string; fileFormat: string; maxSize: string; minSize: string };
  uploadFiles?: (
    files: File[] | null,
    arrayBuffers: Array<string | ArrayBuffer | null> | null,
    errors: FileRenderError[] | null,
    reset: () => void,
  ) => void;
  onChangeBehaviorState?: (p: {
    isDragAccept: boolean;
    isFileDialogActive: boolean;
    isDragActive: boolean;
    isDragReject: boolean;
    isFocused: boolean;
  }) => void;
};

const DEFAULT_MAX_FILE_SIZE = 10 * 1024 * 1024;
// const defaultErrorsTKeys = {
//   defaultError: t('document_Error_File_Format'),
//   fileFormat: t('document_Error_File_Format'),
//   minSize: t('document_Error_File_Size_Zero'),
//   maxSize: t('document_Error_File_Size_Exceeded'),
// }
const defaultErrorsTKeys = {
  defaultError: 'The file format is not supported.',
  fileFormat: 'The file format is not supported.',
  minSize: 'The file size is too small.',
  maxSize: 'The file size is too large.',
};

export function Uploader(props: React.PropsWithChildren<Props>) {
  const {
    accept,
    method = FileReaderMethods.readAsArrayBuffer,
    minSize = 1,
    maxSize = DEFAULT_MAX_FILE_SIZE,
    multiple = false,
    noDrag = false,
    disabled = false,
    uploadFiles,
    errorsTKeys = defaultErrorsTKeys,
    children,
    ...other
  } = props;

  const [{ arrayBuffers, errors, loading, files }, fileSetter, fileReject, reset] = useFileReader({
    method,
    minSize,
    maxSize,
    accept,
    errorsTKeys,
  });

  React.useEffect(() => {
    if (typeof uploadFiles === 'function') {
      uploadFiles(files, arrayBuffers, errors, reset);
    } else {
      reset();
    }
  }, [arrayBuffers, errors, files, loading, reset, uploadFiles]);

  return (
    <BaseUploader
      noDrag={noDrag}
      multiple={multiple}
      disabled={disabled}
      onDrop={fileSetter}
      onDropRejected={fileReject}
      accept={accept}
      sx={{ p: 2, border: '1px dashed grey' }}
      {...other}
    >
      <>
        <LoadingBackdrop loading={loading} />
        {children}
      </>
    </BaseUploader>
  );
}
