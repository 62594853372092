import React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import stripeJs from '@stripe/stripe-js';

import { StripeTextFieldCVC, StripeTextFieldExpiry, StripeTextFieldNumber } from './StripeTextField';

type StripeCartElementEvent =
  | stripeJs.StripeCardNumberElementChangeEvent
  | stripeJs.StripeCardExpiryElementChangeEvent
  | stripeJs.StripeCardCvcElementChangeEvent;

type Props = {
  cardNumberRef: React.MutableRefObject<HTMLElement>;
  // cardHolderRef: React.MutableRefObject<HTMLElement>;
  cardDateRef: React.MutableRefObject<HTMLElement>;
  onUpdateState: (cardFlipped: string, b: boolean) => void;
  onCardInputFocus: (e: any, field: string) => void;
  onCardInputBlur: (e: any) => void;
  checked: boolean;
  children: React.ReactElement;
};

export function CForm(props: Props) {
  const {
    cardNumberRef,
    // cardHolderRef,
    cardDateRef,
    checked,
    onUpdateState,
    onCardInputFocus,
    onCardInputBlur,
    children,
  } = props;

  const onCvvFocus = (e: any) => {
    onUpdateState('isCardFlipped', true);
    onCardInputFocus(e, 'cardCvv');
  };

  const onCvvBlur = (e: any) => {
    onUpdateState('isCardFlipped', false);
    onCardInputBlur(e);
  };

  const [state, setState] = React.useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: undefined,
    expiredError: undefined,
    cvcError: undefined,
  });

  const onElementChange = (field: string, errorField: string) => (event: StripeCartElementEvent) => {
    const { complete, error } = event;
    setState({ ...state, [field]: complete, [errorField]: error?.message });
  };

  const { cardNumberError, expiredError, cvcError } = state;

  return (
    <div className="card-form">
      <div className="card-list">{children}</div>
      <Collapse in={checked}>
        <Box
          sx={{
            borderRadius: '10px',
            padding: { xs: '135px 15px 15px', sm: '165px 25px 25px', md: '180px 35px 35px' },
          }}
          component={Paper}
          elevation={3}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StripeTextFieldNumber
                autoFocus
                margin="normal"
                name="cardNumber"
                InputProps={{ inputRef: cardNumberRef }}
                error={Boolean(cardNumberError)}
                labelErrorMessage={cardNumberError}
                onChange={onElementChange('cardNumberComplete', 'cardNumberError')}
                onBlur={onCardInputBlur}
                onFocus={(e) => onCardInputFocus(e, 'cardNumber')}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <StripeTextFieldExpiry
                margin="normal"
                name="cardDate"
                InputProps={{ inputRef: cardDateRef }}
                error={Boolean(expiredError)}
                labelErrorMessage={expiredError}
                onChange={onElementChange('expiredComplete', 'expiredError')}
                onFocus={(e) => onCardInputFocus(e, 'cardDate')}
                onBlur={onCardInputBlur}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <StripeTextFieldCVC
                margin="normal"
                name="cardCvv"
                error={Boolean(cvcError)}
                labelErrorMessage={cvcError}
                onChange={onElementChange('cvcComplete', 'cvcError')}
                onFocus={onCvvFocus}
                onBlur={onCvvBlur}
              />
            </Grid>
          </Grid>
        </Box>
        {/*  <div className="card-input"> */}
        {/*    <label htmlFor="cardName" className="card-input__label"> */}
        {/*      Card Holder */}
        {/*    </label> */}
        {/*    <input */}
        {/*      id="cardName" */}
        {/*      type="text" */}
        {/*      className="card-input__input" */}
        {/*      autoComplete="off" */}
        {/*      name="cardHolder" */}
        {/*      onChange={handleFormChange} */}
        {/*      ref={cardHolderRef} */}
        {/*      onFocus={(e) => onCardInputFocus(e, 'cardHolder')} */}
        {/*      onBlur={onCardInputBlur} */}
        {/*    /> */}
        {/*  </div> */}
      </Collapse>
    </div>
  );
}
