export enum ResourceType {
  'link' = 'link',
  'pdf' = 'application/pdf',
  'txt' = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export type StorageDataType = number | string | boolean | Array<any> | Record<string, any> | null;

export type LinkResource = {
  name: string;
  type: ResourceType.link;
  url: string;
};

export type Resource = File | LinkResource;

export type State = {
  userEmail: string | null;
  uploadedResources: Array<Resource>;
};

export type AppState = { app: State };
