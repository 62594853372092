import React from 'react';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack, { StackProps } from '@mui/system/Stack';

import { DocxIcon, PdfIcon } from '@core/web/metadialog-shared';
import { useSelector } from '@wpa/redux-utils';

import EmptyDocsImg from '@app/assets/images/docs-empty.png';
import EmailSendImg from '@app/assets/images/email-send.png';
import { LinkIcon } from '@app/components/icons';
import { CircularProgressWithLabel } from '@app/components/step2';
import { NavigateWithQuery } from '@app/helpers/NavigateWithQuery';
import { useNavigateWithQuery } from '@app/helpers/useNavigateWithQuery';
import { AppState, removeOneResource, State } from '@app/store';
import { ResourceType } from '@app/store/types';

export function DocumentsCard({ sx, ...rest }: StackProps) {
  const navigate = useNavigateWithQuery();
  const { uploadedResources: resources } = useSelector<AppState, State>((state) => state?.app);
  const isEmpty = resources.length === 0;

  const LinkIconByType: Record<ResourceType, JSX.Element> = {
    [ResourceType.link]: <LinkIcon sx={{ color: '#DCCAFF' }} />,
    [ResourceType.pdf]: <PdfIcon />,
    [ResourceType.txt]: <DocxIcon />,
  };

  return (
    <Stack
      height="100%"
      sx={{
        height: { xs: '500px', lg: '100%' },
        padding: { xs: '16px', lg: '32px' },
        gap: { xs: '18px', lg: '24px' },
        ...sx,
      }}
      justifyContent="space-between"
      {...rest}
    >
      <Typography sx={{ color: '#CED9E9', fontSize: { xs: 16, lg: 22 }, fontFamily: 'Aeonik Fono', fontWeight: 700 }}>
        My Documents
      </Typography>
      {isEmpty && (
        <Stack alignItems="center" p="40px 20px" spacing={1}>
          <img src={EmptyDocsImg} alt="No files yet..." />
          <Typography
            sx={{
              fontFamily: 'Aeonik Fono',
              fontWeight: 600,
              paddingTop: '10px',
              fontSize: { xs: 14 },
              color: '#778BAE',
            }}
          >
            No files yet...
          </Typography>
          <Typography sx={{ fontSize: { xs: 12 }, color: '#4E6185' }}>
            Please upload some files to continue the work
          </Typography>
        </Stack>
      )}
      {!isEmpty && (
        <List dense sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: '8px', overflow: 'auto' }}>
          {resources.map(({ name, type }, idx) => (
            <ListItem
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                padding: '16px 20px',
                borderRadius: '8px',
                backgroundColor: '#232F48',
              }}
              key={name}
              alignItems="center"
            >
              {LinkIconByType[type as ResourceType] || LinkIconByType[ResourceType.txt]}
              <Typography noWrap sx={{ fontSize: { xs: 16 }, maxWidth: '300px', fontWeight: 600, color: '#CED9E9' }}>
                {name}
              </Typography>
              <IconButton
                onClick={() => removeOneResource(idx)}
                sx={{ marginLeft: 'auto' }}
                edge="end"
                aria-label="delete"
              >
                <DeleteOutlinedIcon sx={{ color: '#4E6185' }} />
              </IconButton>
            </ListItem>
          ))}
        </List>
      )}
      <Button onClick={() => navigate('/steps/2')} size="large" disabled={isEmpty}>
        Test my bot
      </Button>
    </Stack>
  );
}

export function FinishingCard({ ...rest }: StackProps) {
  // hotfix
  const { uploadedResources: resources } = useSelector<AppState, State>((state) => state?.app);
  if (resources.length === 0) return <NavigateWithQuery to="/steps/1" />;

  return (
    <Stack height="100%" {...rest}>
      <img
        width="100%"
        style={{ margin: 'auto', maxWidth: '520px' }}
        height="auto"
        src={EmailSendImg}
        alt="Email send"
      />
    </Stack>
  );
}

export function ProcessingCard({ sx, children, ...rest }: StackProps) {
  // hotfix
  const { uploadedResources: resources } = useSelector<AppState, State>((state) => state?.app);
  if (resources.length === 0) return <NavigateWithQuery to="/steps/1" />;

  return (
    <Stack alignItems="center" height="100%" sx={{ padding: { xs: '16px', lg: '32px' }, ...sx }} {...rest}>
      <Box sx={{ margin: 'auto 0' }}>
        <CircularProgressWithLabel value={75} style={{ width: '250px', height: '250px' }} />
      </Box>
      {children}
    </Stack>
  );
}
