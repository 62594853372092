import { Theme } from '@mui/material';

export function sidenavLogoLabel(theme: Theme) {
  const { functions, transitions, typography } = theme;

  const { pxToRem } = functions;
  const { fontWeightMedium } = typography;

  return {
    ml: 0.5,
    fontWeight: fontWeightMedium,
    wordSpacing: pxToRem(-1),
    transition: transitions.create(['opacity', 'margin'], {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),
  };
}
