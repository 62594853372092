import React from 'react';
import { isMobile } from 'react-device-detect';
import { Navigate, useLocation } from 'react-router';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { isEmpty } from 'lodash';

import { useSelector } from '@wpa/redux-utils';

import { AppState, State } from '@app/store';

import { EmailIcon } from '../icons';
import { MainCard } from '../MainCard';

export function ConfirmationSent() {
  const location = useLocation();
  const { uploadedResources } = useSelector<AppState, State>((state) => state?.app);

  if (isEmpty(uploadedResources) || !location.state?.email) {
    return <Navigate to="/steps/1" />;
  }

  return (
    <MainCard>
      <Grid container spacing={2} columns={6}>
        {isMobile && (
          <Grid xs={6} justifyContent="center" sx={{ display: 'flex' }}>
            <EmailIcon viewBox="0 0 528 476" sx={{ width: '100%', height: '100%' }} />
          </Grid>
        )}
        <Grid xs={6}>
          <Typography variant="h4">We sent a confirmation link to:</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography variant="h5">{location.state?.email}</Typography>
        </Grid>
        <Grid xs={6}>
          <Typography variant="h5" color="text.secondary">
            Check your inbox for the next steps.
          </Typography>
        </Grid>
      </Grid>
    </MainCard>
  );
}
