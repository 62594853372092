import Drawer from '@mui/material/Drawer';
import styled from '@mui/material/styles/styled';

import { ViewState } from '../../../theme';

type OwnerState = {
  miniSidenav: ViewState['miniSidenav'];
};

type Props = {
  ownerState: OwnerState;
};

export const SidenavRoot = styled(Drawer)<Props>(({ theme, ownerState }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { miniSidenav } = ownerState;

  const sidebarWidth = 300;
  const { background } = palette;
  const { xxl } = boxShadows;
  const { pxToRem } = functions;

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    transform: 'translateX(0)',
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up('lg')]: {
      boxShadow: xxl,
      marginBottom: 'inherit',
      left: '0',
      width: sidebarWidth,
      transform: 'translateX(0)',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create('transform', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up('lg')]: {
      boxShadow: xxl,
      marginBottom: 'inherit',
      left: '0',
      width: pxToRem(96),
      overflowX: 'hidden',
      transform: 'translateX(0)',
      transition: transitions.create(['width', 'background-color'], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    '& .MuiDrawer-paper': {
      boxShadow: xxl,
      border: 'none',
      background: background.paper,
      // background: linearGradient(gradients.sidenav.main, gradients.sidenav.state, gradients.sidenav.deg),
      // backdropFilter: 'blur(120px)',
      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
});
