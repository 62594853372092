import React from 'react';

import { createUseReducer } from '@wpa/redux-utils';

import { initialState, rootReducer } from './stateSlice';
import { State } from './types';

const useReducer = createUseReducer<State>('app', rootReducer, { cache: false });

export function Store({ children }: React.PropsWithChildren<any>) {
  const [state] = useReducer(initialState() as State);

  if (process.env.NODE_ENV === 'development') {
    console.log('state', state);
  }

  return children;
}
