/* eslint-disable no-param-reassign */
import { Action, bindActionCreators, createSlice, dispatch, ReducerCase, ReducerCases } from '@wpa/redux-utils';

import { StorageDataType, ViewState } from './types';

function setToStorage(key: string, data: StorageDataType) {
  window.localStorage.setItem(key, JSON.stringify(data));
}

function getAndUpdateIfNeededValueFromStorage<T extends StorageDataType = StorageDataType>(key: string, fallback: T) {
  try {
    const storageItem = window.localStorage.getItem(key);
    let result = fallback;

    if (storageItem !== null) {
      result = JSON.parse(storageItem);
    } else {
      setToStorage(key, fallback);
    }

    return result;
  } catch {
    setToStorage(key, fallback);
    return fallback;
  }
}

export const initialState = () => ({
  miniSidenav: getAndUpdateIfNeededValueFromStorage<boolean>('miniSidenav', false),
  transparentSidenav: false,
  sidenavColor: 'info',
  transparentNavbar: true,
  fixedNavbar: true,
  direction: 'ltr',
});

type Cases<S> = {
  setMiniSidenav: ReducerCase<S, Action<boolean>>;
  setTransparentSidenav: ReducerCase<S, Action<boolean>>;
  setSidenavColor: ReducerCase<S, Action<ViewState['sidenavColor']>>;
  setTransparentNavbar: ReducerCase<S, Action<boolean>>;
  setFixedNavbar: ReducerCase<S, Action<boolean>>;
  setDirection: ReducerCase<S, Action<string>>;
};

const { actions, reducer } = createSlice<ViewState, ReducerCases<ViewState, Action<any>, Cases<ViewState>>>({
  name: '@app',
  withActionPrefix: false,
  reducerCases: {
    setMiniSidenav: (draft, { payload }) => {
      setToStorage('miniSidenav', payload);
      draft.miniSidenav = payload;
    },
    setTransparentSidenav: (draft, { payload }) => {
      draft.transparentSidenav = payload;
    },
    setSidenavColor: (draft, { payload }) => {
      draft.sidenavColor = payload;
    },
    setTransparentNavbar: (draft, { payload }) => {
      draft.transparentNavbar = payload;
    },
    setFixedNavbar: (draft, { payload }) => {
      draft.fixedNavbar = payload;
    },
    setDirection: (draft, { payload }) => {
      draft.direction = payload;
    },
  },
});

const { setMiniSidenav, setTransparentSidenav, setSidenavColor, setTransparentNavbar, setFixedNavbar, setDirection } =
  bindActionCreators<typeof actions>(actions, dispatch);

export {
  reducer as rootReducer,
  setDirection,
  setFixedNavbar,
  setMiniSidenav,
  setSidenavColor,
  setTransparentNavbar,
  setTransparentSidenav,
};
