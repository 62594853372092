/* eslint-disable no-param-reassign,react/static-property-placement */
import React from 'react';
import ErrorStackParser from 'error-stack-parser';
import { mapStackTrace } from 'sourcemapped-stacktrace';
import StackFrame from 'stackframe';

import baseStyle from './styles';
import { Props } from './types';
import { isFilenameAbsolute, makeLinkText, makeUrl } from './utils';

type State = { error: Error | null; mapped: boolean };

export class ErrorView extends React.Component<Props, State> {
  static displayName: string;

  static defaultProps: { filename: string | undefined; useLines: boolean; useColumns: boolean };

  constructor(props: Props | Readonly<Props>) {
    super(props);

    this.mapOnConstruction(props.error);
    // State is used to store the error mapped to the source map.
    this.state = { error: null, mapped: false };
  }

  componentDidMount() {
    const { mapped } = this.state;
    const { error } = this.props;

    if (!mapped) {
      this.mapError(error);
    }
  }

  // Try to map the error when the component gets constructed, this is possible
  // in some cases like evals.
  mapOnConstruction(error: Error) {
    const stackLines = error?.stack?.split('\n');

    // There's no stack, only the error message.
    if (stackLines && stackLines.length < 2) {
      this.state = { error, mapped: true };
      return;
    }

    // Using the “eval” setting on webpack already gives the correct location.
    const isWebpackEval = stackLines && stackLines[1].search(/\(webpack:\/{3}/) !== -1;
    if (isWebpackEval) {
      // No changes are needed here.
      this.state = { error, mapped: true };
      return;
    }

    // Other eval follow a specific pattern and can be easily parsed.
    const isEval = stackLines && stackLines[1].search(/\(eval at/) !== -1;
    if (!isEval) {
      // mapping will be deferred until `componentDidMount`
      this.state = { error, mapped: false };
      return;
    }

    // The first line is the error message.
    const fixedLines = [stackLines.shift()];

    // The rest needs to be fixed.
    stackLines.forEach((stackLine) => {
      const evalStackLine = stackLine.match(/(.+)\(eval at (.+) \(.+?\), .+(:\d+:\d+)\)/);

      if (evalStackLine) {
        const [, atSomething, file, rowColumn] = evalStackLine;
        fixedLines.push(`${atSomething} (${file}${rowColumn})`);
      } else {
        // TODO: When stack frames of different types are detected, try to load the additional source maps
        fixedLines.push(stackLine);
      }
    });

    error.stack = fixedLines.join('\n');
    this.state = { error, mapped: true };
  }

  mapError(error: Error) {
    mapStackTrace(error.stack, (mappedStack) => {
      error.stack = mappedStack.join('\n');
      this.setState({ error, mapped: true });
    });
  }

  renderFrames(frames: StackFrame[] = []) {
    const { filename, editorScheme, useLines, useColumns, style } = this.props;
    const { frame, file, linkToFile } = { ...baseStyle, ...style };
    return frames.map((f, index) => {
      let text;
      let url;

      if (index === 0 && filename && !isFilenameAbsolute(f.fileName || '')) {
        url = makeUrl(filename, editorScheme);
        text = makeLinkText(filename);
      } else {
        const lines = useLines ? f.lineNumber : undefined;
        const columns = useColumns ? f.columnNumber : undefined;
        url = makeUrl(f.fileName || '', editorScheme, lines, columns);
        text = makeLinkText(f.fileName || '', lines, columns);
      }

      return (
        <div style={frame} key={`$_${index + 1}`}>
          <div>{f.functionName}</div>
          <div style={file}>
            <a href={url} style={linkToFile}>
              {text}
            </a>
          </div>
        </div>
      );
    });
  }

  render() {
    // The error is received as a property to initialize state.error, which may
    // be updated when it is mapped to the source map.
    const { error } = this.state;

    const { className, style } = this.props;
    const { errorOverlay, message, stack, frame } = { ...baseStyle, ...style };

    let frames;
    let parseError;
    try {
      if (error) {
        frames = ErrorStackParser.parse(error);
      }
    } catch {
      parseError = new Error('Failed to parse stack trace. Stack trace information unavailable.');
    }

    frames = parseError ? (
      <div style={frame} key={0}>
        <div>{parseError.message}</div>
      </div>
    ) : (
      this.renderFrames(frames)
    );

    return (
      <div style={errorOverlay} className={className}>
        <div style={message}>{`${error?.name}:${error?.message}`}</div>
        <div style={stack}>{frames}</div>
      </div>
    );
  }
}

ErrorView.displayName = 'ErrorView';
ErrorView.defaultProps = {
  filename: undefined,
  useLines: true,
  useColumns: true,
};
