import React from 'react';

import { MdTypography } from '@core/web/look';

import { SidebarRight } from './SidebarRight';

export function WelcomeText() {
  return (
    <SidebarRight sx={{ padding: { xs: '4px 0 16px', lg: '0 88px' } }}>
      <MdTypography
        variant="h2"
        sx={{
          background: '-webkit-linear-gradient(255.02deg, #FFEE9E 0%, #FE85A6 50.52%, #AE8DF5 100%)',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      >
        Fully Automated Chatbot Within Minutes
      </MdTypography>
      <MdTypography variant="body2" sx={{ color: '#96A8C7' }}>
        Add a link to your website help center, or upload any documents you want, let our system learn from it
        automatically, then ask any question you have and get instant responses.
      </MdTypography>
    </SidebarRight>
  );
}
