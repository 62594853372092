/* eslint-disable no-param-reassign,dot-notation */
import { get } from 'lodash';

function isBrowser() {
  return Boolean(typeof window !== 'undefined' && window.__env);
}

function getFiltered() {
  const processEnv = get(process, ['env']);
  const prefix = processEnv.DYNAMIC_ENV_PREFIX || 'MD';

  return Object.keys(processEnv)
    .filter((key) => new RegExp(`^${prefix}_`, 'i').test(key))
    .reduce((ev, key) => {
      ev[key] = processEnv[key];

      return ev;
    }, {});
}

export function env(key = '') {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  const prefix = (isBrowser() ? window.__env.DYNAMIC_ENV_PREFIX : process['env'].DYNAMIC_ENV_PREFIX) || 'MD';
  const safeKey = `${prefix}_${key}`;

  if (isBrowser()) {
    return key.length > 0 ? window.__env[safeKey] : window.__env;
  }
}
