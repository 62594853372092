import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';

import { useSelector } from '@wpa/redux-utils';

import { AppState, State } from '@app/store';

type StepDescriptionProps = React.PropsWithChildren<{
  step?: string;
  title: string;
  description: string | JSX.Element;
  isActive?: boolean;
}>;

function StepDescription({ step, title, description, isActive = true, children }: StepDescriptionProps) {
  return (
    <Box sx={{ color: '#2C3B58', display: 'flex' }}>
      <Box
        sx={{
          ...(isActive && {
            width: '6px',
            mr: { xs: '16px', lg: '32px' },
            background: 'linear-gradient(0deg, #AE8DF5 0%, #FE85A6 50%, #FFEE9E 100%);',
          }),
        }}
      />
      <Stack spacing={1}>
        {step && (
          <Typography
            sx={{
              fontFamily: 'Aeonik Fono',
              fontWeight: 700,
              fontSize: { xs: 16, lg: 22 },
              color: isActive ? '#778BAE' : 'inherit',
            }}
          >
            #{step}
          </Typography>
        )}

        <Typography
          variant="h2"
          sx={{
            fontFamily: 'Aeonik Fono',
            fontWeight: 700,
            lineHeight: '1.2',
            fontSize: { xs: 22, lg: 28 },
            color: 'inherit',
            ...(isActive && {
              fontSize: { xs: 28, lg: 48 },
              background: 'linear-gradient(130deg, #AE8DF5 0%, #FE85A6 50%, #FFEE9E 100%);',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }),
          }}
        >
          {title}
        </Typography>
        <Typography sx={{ fontSize: { xs: 14, lg: 16 }, color: isActive ? '#CED9E9' : 'inherit' }}>
          {description}
        </Typography>
        {isActive && children}
      </Stack>
    </Box>
  );
}

export function UploadingStepDescription({ ...rest }: Partial<StepDescriptionProps>) {
  return (
    <StepDescription
      step="01"
      title="Upload your knowledge base"
      description="Add a link to your website help center or upload any document you want. Let Metadialog learn from it and get
          your superhuman chatbot ready in minutes"
      {...rest}
    />
  );
}

export function ProcessingStepDescription({ ...rest }: Partial<StepDescriptionProps>) {
  return (
    <StepDescription
      step="02"
      title="Test your bot"
      description="The bot is now learning all the information that you provided, it will soon be able to talk to you about any of the topics you uploaded, just like a human agent."
      {...rest}
    />
  );
}

export function FinishingStepDescription({ ...rest }: Partial<StepDescriptionProps>) {
  const { userEmail } = useSelector<AppState, State>((state) => state?.app);

  return (
    <StepDescription
      title="Let's confirm your email address"
      description={
        <>
          We sent a confirmation link to: <b>{userEmail}</b> <br />
          Check your inbox for the next steps.
        </>
      }
      {...rest}
    />
  );
}
