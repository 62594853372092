import { ILogger } from '../interfaces/logger.interface';

export class FallbackLogger implements ILogger {
  private logger: Console;

  private target: string;

  private readonly id: string;

  constructor(target?: string) {
    this.logger = console;
    this.id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(10);
    this.target = target ? `${target}(${FallbackLogger.name})` : `${FallbackLogger.name}(${this.id})`;

    if (process.env.IS_DEV) {
      this.logger.log(`Created a ${FallbackLogger.name} for target: ${this.target}`);
    }
  }

  for(target: string): void {
    const newTarget = `${target}(${FallbackLogger.name})`;

    if (process.env.IS_DEV) {
      this.logger.log(`Changed logger target from => ${this.target} to => ${newTarget}`);
    }

    this.target = newTarget;
  }

  info(messages: string, ...meta: any[]) {
    this.logger.log(`[INFO]: (${this.target}): ${messages}`, ...meta);
  }

  debug(messages: string, ...meta: any[]) {
    this.logger.log(`[DEBUG]: (${this.target}): ${messages}`, ...meta);
  }

  warn(messages: string, ...meta: any[]) {
    this.logger.warn(`[WARN]: (${this.target}): ${messages}`, ...meta);
  }

  error(messages: string | Error, ...meta: any[]) {
    if (typeof messages === 'object') {
      this.logger.error(`[ERROR]: (${this.target}):`, messages, ...meta);
    } else {
      this.logger.error(`[ERROR]: (${this.target}): ${messages}`, ...meta);
    }
  }
}
