import React from 'react';
import { ResizeObserver } from '@juggle/resize-observer';

export type Size = {
  height: number;
  width: number;
};

type Props = {
  /** Function responsible for rendering children. */
  children: (size: Size) => JSX.Element;

  /** Optional custom CSS class name to attach to root AutoSizer element.  */
  className?: string;

  /** Default height to use for initial render; useful for SSR */
  defaultHeight?: number;

  /** Default width to use for initial render; useful for SSR */
  defaultWidth?: number;

  /** Disable dynamic :height property */
  disableHeight?: boolean;

  /** Disable dynamic :width property */
  disableWidth?: boolean;

  /** Callback to be invoked on-resize */
  onResize?: (size: Size) => void;

  /** Optional inline style */
  style?: Record<string, any>;
};

type State = {
  height: number;
  width: number;
};

export class AutoSizer extends React.Component<Props, State> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    onResize: () => undefined,
    disableHeight: false,
    disableWidth: false,
    style: {},
  };

  _parentNode?: Node & ParentNode;

  _autoSizer: React.RefObject<HTMLDivElement>;

  _detectElementResize?: ResizeObserver;

  constructor(props: Props) {
    super(props);

    this.state = {
      height: props.defaultHeight || 0,
      width: props.defaultWidth || 0,
    };

    this._autoSizer = React.createRef();
  }

  componentDidMount() {
    if (this._autoSizer.current?.parentNode?.ownerDocument?.defaultView) {
      this._parentNode = this._autoSizer.current.parentNode;

      this._detectElementResize = new ResizeObserver(this._onResize);
      this._detectElementResize.observe(this._parentNode as Element);
    }
  }

  componentWillUnmount() {
    if (this._detectElementResize && this._parentNode) {
      this._detectElementResize.disconnect();
    }
  }

  _onResize = (entries: ResizeObserverEntry[]): void => {
    const { disableHeight, disableWidth, onResize } = this.props;
    const { width, height } = this.state;

    if (
      Array.isArray(entries) &&
      entries[0] &&
      Array.isArray(entries[0].contentBoxSize) &&
      entries[0].contentBoxSize[0]
    ) {
      const { inlineSize, blockSize } = entries[0].contentBoxSize[0];

      const calcHeight = blockSize || 0;
      const calcWidth = inlineSize || 0;

      if ((!disableHeight && height !== calcHeight) || (!disableWidth && width !== calcWidth)) {
        this.setState({ height: calcHeight, width: calcWidth });

        if (onResize) {
          onResize({ height: calcHeight, width: calcWidth });
        }
      }
    }
  };

  render() {
    const { children, className, disableHeight, disableWidth, style } = this.props;
    const { height, width } = this.state;

    const outerStyle: {
      overflow: string;
      width?: number;
      height?: number;
    } = {
      overflow: 'visible',
      width: undefined,
      height: undefined,
    };
    const childParams: {
      width?: number;
      height?: number;
    } = {
      width: undefined,
      height: undefined,
    };

    if (!disableHeight) {
      outerStyle.height = 0;
      childParams.height = height;
    }

    if (!disableWidth) {
      outerStyle.width = 0;
      childParams.width = width;
    }

    return (
      <div
        className={className}
        ref={this._autoSizer}
        style={{
          ...outerStyle,
          ...style,
        }}
      >
        {children(childParams as Size)}
      </div>
    );
  }
}
