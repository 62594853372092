import React, { ErrorInfo } from 'react';

import { ErrorOverlay } from './ErrorOverlay';

type MainState = {
  error: Error | null;
  errorInfo: ErrorInfo | null;
};

export class ErrorBoundary extends React.Component<React.PropsWithChildren<any>, MainState> {
  constructor(props: void) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // Catch errors in any components below and re-render with error message
    this.setState({ error, errorInfo });
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { error, errorInfo } = this.state;
    console.log('errorInfo', errorInfo);
    // if (errorInfo) {
    //   return (
    //     <div>
    //       <h2>Something went wrong.</h2>
    //       <details style={{ whiteSpace: 'pre-wrap' }}>
    //         {error?.toString()}
    //         <br />
    //         {errorInfo.componentStack}
    //       </details>
    //     </div>
    //   );
    // }

    if (error) {
      return <ErrorOverlay editorScheme="webstorm" error={error} />;
    }
    // Normally, just render children
    return this.props.children;
  }
}
