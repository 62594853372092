import React from 'react';
import { UNSAFE_NavigationContext } from 'react-router-dom';
import { Blocker, History, Transition } from 'history';

export function useBlocker(blocker: Blocker, when = true): void {
  const navigator = React.useContext(UNSAFE_NavigationContext).navigator as History;

  React.useEffect(() => {
    let unblock = function noop() {};

    if (when) {
      unblock = navigator.block((tx: Transition) => {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            unblock();
            tx.retry();
          },
        };

        blocker(autoUnblockingTx);
      });
    }

    return () => {
      unblock();
    };
  }, [navigator, blocker, when]);
}
