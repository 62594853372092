import React from 'react';
import { Accept, DropEvent, FileError, FileRejection } from 'react-dropzone';
import accepts from 'attr-accept';
import { isEmpty } from 'lodash';

import { FileReaderMethods } from '../file-reader-method.enum';

const fileAccepted = (file: File, accept: string | string[]) =>
  file.type === 'application/x-moz-file' || accepts(file, accept);
const matchMaxSizeSize = (file: File, maxSize: number) => file.size <= maxSize;
const matchMinSizeSize = (file: File, minSize: number) => file.size >= minSize;

export type FileRenderError = {
  name: string;
  message: string;
  file: File | null;
  errors?: FileError[];
};

export type FileReaderResult = {
  arrayBuffers: Array<string | ArrayBuffer | null> | null;
  files: File[] | null;
  errors: FileRenderError[] | null;
  loading: boolean;
};

export type FileReaderTuple = [
  FileReaderResult,
  (acceptedFiles: File[]) => void,
  (fileRejections: FileRejection[], event: DropEvent) => void,
  () => void,
];

type Props = {
  method: FileReaderMethods;
  minSize: number;
  maxSize: number;
  errorsTKeys: { defaultError: string; fileFormat: string; maxSize: string; minSize: string };
  accept?: Accept;
  onload?: (args?: any) => Record<string, any>;
};

type State = {
  loading: boolean;
  files: File[] | null;
  errors: FileRenderError[] | null;
  arrayBuffers: Array<string | ArrayBuffer | null> | null;
};

function readFileAsync(file: File, method: FileReaderMethods): Promise<string | ArrayBuffer | null> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener('load', () => {
      resolve(reader.result);
    });

    reader.onerror = reject;

    reader[method](file);
  });
}

const defaultState = {
  loading: false,
  files: null,
  errors: null,
  arrayBuffers: null,
};

export const useFileReader = (options: Props): FileReaderTuple => {
  const { method, accept, minSize, maxSize, errorsTKeys, onload: onloadHook } = options;

  const [state, setState] = React.useState<State>(() => defaultState);

  React.useEffect(() => {
    (async () => {
      if (state.files) {
        try {
          switch (method) {
            case FileReaderMethods.readAsText:
            case FileReaderMethods.readAsArrayBuffer:
            case FileReaderMethods.readAsBinaryString:
            case FileReaderMethods.readAsDataURL: {
              // eslint-disable-next-line no-lone-blocks
              {
                const result = await Promise.allSettled(
                  state.files.map(async (file) => {
                    try {
                      return await readFileAsync(file, method);
                    } catch {
                      setState((prevState) => ({
                        ...prevState,
                        errors: [
                          ...(prevState.errors || []),
                          { name: 'defaultError', message: errorsTKeys.defaultError, file: null },
                        ],
                      }));

                      return null;
                    }
                  }),
                );

                const arrayBuffers = result.map(({ value }: any) => value);

                if (typeof onloadHook === 'function') {
                  onloadHook(arrayBuffers);
                }

                setState((prevState) => ({
                  ...prevState,
                  arrayBuffers,
                  loading: false,
                }));
              }

              break;
            }
            default: {
              throw new Error('Unknown method');
            }
          }
        } catch (error: any) {
          setState((prevState) => ({
            ...prevState,
            errors: [...(prevState.errors || []), { name: 'Unknown Error', message: error.message, file: null }],
          }));
        }
      }
    })();
  }, [errorsTKeys.defaultError, method, onloadHook, state.files]);

  const fileSetter = React.useCallback(
    (acceptedFiles: File[]) => {
      setState((prevState) => ({ ...prevState, loading: true }));

      if (isEmpty(acceptedFiles)) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          errors: [
            ...(prevState.errors || []),
            { name: 'defaultError', message: errorsTKeys.defaultError, file: null },
          ],
        }));

        return;
      }

      const validationErrorsAcc: Array<FileRenderError> = [];
      const filesAcc: Array<File> = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const file of acceptedFiles) {
        const validationErrors: Array<FileRenderError> = [];
        if (!matchMaxSizeSize(file, maxSize)) {
          validationErrors.push({ name: 'maxSize', message: errorsTKeys.maxSize, file });
        }

        if (!matchMinSizeSize(file, minSize)) {
          validationErrors.push({ name: 'minSize', message: errorsTKeys.minSize, file });
        }

        if (!fileAccepted(file, Object.keys(accept || {}))) {
          validationErrors.push({ name: 'fileFormat', message: errorsTKeys.fileFormat, file });
        }

        if (isEmpty(validationErrors)) {
          filesAcc.push(file);
        }

        validationErrorsAcc.push(...validationErrors);
      }

      setState((prevState) => ({
        ...prevState,
        files: filesAcc,
        errors: [...(prevState.errors || []), ...validationErrorsAcc],
      }));
    },
    [
      accept,
      errorsTKeys.defaultError,
      errorsTKeys.fileFormat,
      errorsTKeys.maxSize,
      errorsTKeys.minSize,
      maxSize,
      minSize,
    ],
  );

  const fileReject = React.useCallback(
    (rejectedFiles: FileRejection[]) => {
      if (!isEmpty(rejectedFiles)) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
          errors: [
            ...(prevState.errors || []),
            ...rejectedFiles.map((rejectedFile) => ({
              name: 'defaultError',
              message: errorsTKeys.defaultError,
              file: rejectedFile.file,
              errors: rejectedFile.errors,
            })),
          ],
        }));
      }
    },
    [errorsTKeys.defaultError],
  );

  const reset = () => setState(defaultState);

  return [{ ...state }, fileSetter, fileReject, reset];
};
