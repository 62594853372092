import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';

type Props = {
  domain: string;
  clientId: string;
  redirectUri: string;
  audience: string;
};
export function AuthzProvider(props: React.PropsWithChildren<Props>) {
  const { domain, clientId, redirectUri, audience, children } = props;
  const navigate = useNavigate();

  if (!domain || !clientId) {
    throw new Error('Please set AUTH0_DOMAIN and CLIENT_ID env. variables');
  }

  const onRedirectCallback: Auth0ProviderOptions['onRedirectCallback'] = (appState) => {
    navigate(appState?.returnTo || redirectUri);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      audience={audience}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens
      // cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
}
