import 'reflect-metadata';

import React from 'react';
import { createRoot } from 'react-dom/client';
import FontFaceObserver from 'fontfaceobserver';

import { App } from './App';

const container = document.querySelector('#root');
const root = createRoot(container!);

root.render(<App />);
