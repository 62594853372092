/* eslint-disable react/require-default-props */
import React from 'react';
import { Else, If, Then } from 'react-if';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { alpha } from '@mui/material';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import { FormikErrors, FormikTouched } from 'formik';

type Props = {
  readOnly?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  touched?: boolean | FormikTouched<any> | FormikTouched<any>[];
  id: string;
  name: string;
  label: string;
  value?: string;
  error?: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  autoComplete?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  endAdornment?: React.ReactNode;
  margin?: 'none' | 'dense' | 'normal';
};

export function SecureInput(props: Props) {
  const {
    readOnly,
    required,
    fullWidth,
    touched,
    id,
    name,
    label,
    value,
    error,
    autoComplete,
    onChange,
    endAdornment,
    margin,
  } = props;

  const [showValue, setShowValue] = React.useState(false);

  const toggleShowValue = React.useCallback(() => setShowValue((prev) => !prev), []);

  return (
    <FormControl
      required={required}
      fullWidth={fullWidth}
      variant="filled"
      error={touched && Boolean(error)}
      margin={margin}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <FilledInput
        readOnly={readOnly}
        disableUnderline
        name={name}
        id={id}
        type={showValue ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        endAdornment={
          <If condition={!!endAdornment}>
            <Then>{endAdornment}</Then>
            <Else>
              <InputAdornment position="end">
                <IconButton onClick={toggleShowValue} onMouseDown={toggleShowValue} edge="end">
                  {showValue ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </Else>
          </If>
        }
        autoComplete={autoComplete}
        sx={(theme) => ({
          border: '1px solid #e2e2e1',
          overflow: 'hidden',
          borderRadius: theme.customization.borderRadius,
          // @ts-expect-error: noop
          backgroundColor: theme.palette.dark['800'],
          transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
          '&:hover': {
            backgroundColor: 'transparent',
          },
          '&.Mui-focused': {
            backgroundColor: 'transparent',
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
          },
        })}
      />
      {touched && <FormHelperText id={`${id}-helper-text`}>{error as string}</FormHelperText>}
    </FormControl>
  );
}
