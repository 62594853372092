import React from 'react';
import { Location, NavLink as RouterNavLink, NavLinkProps, To, useLocation } from 'react-router-dom';
import MaterialLink, { LinkProps } from '@mui/material/Link';

function getTo(location: Location, to: To, href?: string): To {
  let partialPath = typeof to === 'object' ? to : { pathname: to };

  if (href && String(href).startsWith('#')) {
    partialPath = { pathname: location.pathname, hash: href };
  } else if (href && !String(href).startsWith('#')) {
    partialPath = { pathname: href };
  }

  return partialPath;
}

export const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps & LinkProps>(
  ({ sx, to, href, ...otherProps }, ref) => {
    const location = useLocation();

    return (
      <MaterialLink
        ref={ref}
        to={getTo(location, to, href)}
        rel="noreferrer"
        component={RouterNavLink}
        sx={{ textDecoration: 'none', ...sx }}
        {...otherProps}
      />
    );
  },
);
