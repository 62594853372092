import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';

export function Loading() {
  return (
    <Container sx={{ height: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper elevation={0} sx={{ background: 'transparent', paddingBottom: '300px' }}>
        <Fade in style={{ transitionDelay: '800ms' }} unmountOnExit>
          <CircularProgress color="primary" />
        </Fade>
      </Paper>
    </Container>
  );
}
