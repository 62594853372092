import React from 'react';
import { TypographyProps } from '@mui/material/Typography';

import { MdTypographyRoot } from './MdTypographyRoot';

type Props = {
  color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'light'
    | 'dark'
    | 'text'
    | 'white'
    | 'logo';
  fontWeight?: 'light' | 'regular' | 'medium' | 'bold';
  textTransform?: 'none' | 'capitalize' | 'uppercase' | 'lowercase';
  verticalAlign?: 'unset' | 'baseline' | 'sub' | 'super' | 'text-top' | 'text-bottom' | 'middle' | 'top' | 'bottom';
  textGradient?: boolean;
  opacity?: number;
};

export const MdTypography = React.forwardRef<HTMLSpanElement, React.PropsWithChildren<TypographyProps<any, Props>>>(
  (props, ref) => {
    const {
      color = 'white',
      fontWeight = false,
      textTransform = 'none',
      verticalAlign = 'unset',
      textGradient = false,
      opacity = 1,
      children,
      ...rest
    } = props;

    return (
      <MdTypographyRoot
        {...rest}
        ref={ref}
        // @ts-expect-error: noop
        ownerState={{
          color,
          textTransform,
          verticalAlign,
          fontWeight,
          opacity,
          textGradient,
        }}
      >
        {children}
      </MdTypographyRoot>
    );
  },
);
