import React from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { split } from '@apollo/client/link/core';
import { onError } from '@apollo/client/link/error';
import { HttpLink } from '@apollo/client/link/http';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { useAuth0 } from '@auth0/auth0-react';
import { getOperationAST } from 'graphql';
import { createClient } from 'graphql-ws';

// import { ServerSentEventsLink } from './sub/SseLink';

// import { SseLink } from './SseLink';

export function AuthorizedApolloProvider({ serverPort, children }: React.PropsWithChildren<{ serverPort: number }>) {
  const { getAccessTokenSilently, user } = useAuth0();

  const client = React.useMemo(() => {
    // @ts-expect-error: noop
    const processEnv = process.env;
    const port = processEnv.IS_DEV ? `:${serverPort}` : '';
    const uri = `${window.location.protocol}//${window.location.hostname}${port}`;
    const httpLink = new HttpLink({ uri: `${uri}${processEnv.API_URL}` });
    const websocketProtocol = processEnv.IS_DEV ? 'ws' : 'wss';

    const wsLink = new GraphQLWsLink(
      createClient({
        url: `${websocketProtocol}://${window.location.hostname}${port}/graphql`,
        lazy: false,
      }),
    );

    // const sseLink = new SseLink({ url: `${uri}/graphql/stream` });
    // const sseLink = new ServerSentEventsLink({ graphQlSubscriptionUrl: `${uri}/graphql` });

    const httpWsLinkSplit = split(
      ({ query, operationName }) => {
        const definition = getOperationAST(query, operationName);

        return definition?.kind === 'OperationDefinition' && definition.operation === 'subscription';
      },
      wsLink,
      httpLink,
    );

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
        );
      }
      if (networkError) {
        console.error(`[Network error]:`, networkError);
      }
    });

    const addVariableLink = new ApolloLink((operation, forward) => {
      // eslint-disable-next-line no-param-reassign
      operation.variables = {
        ...operation.variables,
        userUid: user?.sub as string,
      };
      return forward(operation);
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessTokenSilently();

      return {
        headers: {
          ...headers,
          ...(token ? { Authorization: `Bearer ${token}` } : {}),
        },
      };
    });

    return new ApolloClient({
      link: ApolloLink.from([errorLink, authLink, addVariableLink, httpWsLinkSplit]),
      cache: new InMemoryCache(),
      connectToDevTools: true,
    });
    // TODO check this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
