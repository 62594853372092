import { useRef } from 'react';

export function useConstant(fn: any) {
  const ref = useRef<any>();

  if (!ref.current) {
    ref.current = { v: fn() };
  }

  return ref.current.v;
}
