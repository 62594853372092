import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export function PdfIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <g filter="url(#filter0_d_372_3651)">
        <path
          d="M15.3431 0.5H14L15.5 9L24.5 14V9.65685C24.5 8.59599 24.0786 7.57857 23.3284 6.82843L18.1716 1.67157C17.4214 0.921427 16.404 0.5 15.3431 0.5Z"
          fill="white"
        />
        <path
          d="M14 0.5V0H13.404L13.5076 0.586893L14 0.5ZM18.1716 1.67157L17.818 2.02513L17.818 2.02513L18.1716 1.67157ZM23.3284 6.82843L22.9749 7.18198H22.9749L23.3284 6.82843ZM24.5 14L24.2572 14.4371L25 14.8498V14H24.5ZM15.5 9L15.0076 9.08689L15.049 9.32142L15.2572 9.43708L15.5 9ZM14 1H15.3431V0H14V1ZM17.818 2.02513L22.9749 7.18198L23.682 6.47487L18.5251 1.31802L17.818 2.02513ZM24 9.65685V14H25V9.65685H24ZM24.7428 13.5629L15.7428 8.56292L15.2572 9.43708L24.2572 14.4371L24.7428 13.5629ZM15.9924 8.91311L14.4924 0.413107L13.5076 0.586893L15.0076 9.08689L15.9924 8.91311ZM22.9749 7.18198C23.6313 7.83836 24 8.7286 24 9.65685H25C25 8.46338 24.5259 7.31879 23.682 6.47487L22.9749 7.18198ZM15.3431 1C16.2714 1 17.1616 1.36875 17.818 2.02513L18.5251 1.31802C17.6812 0.474106 16.5366 0 15.3431 0V1Z"
          fill="#A8B8D2"
        />
        <path
          d="M1.5 2C1.5 1.17157 2.17157 0.5 3 0.5H15.5C16.3284 0.5 17 1.17157 17 2V5.12889C17 6.44505 18.0205 7.53581 19.3337 7.62336L23.0998 7.87443C23.8877 7.92696 24.5 8.58141 24.5 9.37111V26C24.5 26.8284 23.8284 27.5 23 27.5H3C2.17157 27.5 1.5 26.8284 1.5 26V2Z"
          fill="white"
          stroke="#A8B8D2"
        />
      </g>
      <rect
        x="25.5"
        y="12.5"
        width="12"
        height="25"
        rx="1.5"
        transform="rotate(90 25.5 12.5)"
        fill="#EF5C5C"
        stroke="#CE4C4C"
      />
      <g filter="url(#filter1_d_372_3651)">
        <path
          d="M5.63746 21V15.1818H7.81928C8.26625 15.1818 8.64125 15.2652 8.94428 15.4318C9.2492 15.5985 9.47931 15.8277 9.63462 16.1193C9.79181 16.4091 9.87041 16.7386 9.87041 17.108C9.87041 17.4811 9.79181 17.8125 9.63462 18.1023C9.47742 18.392 9.24541 18.6203 8.93859 18.7869C8.63178 18.9517 8.25393 19.0341 7.80507 19.0341H6.35905V18.1676H7.66303C7.92439 18.1676 8.1384 18.1222 8.30507 18.0312C8.47174 17.9403 8.59484 17.8153 8.67439 17.6562C8.75583 17.4972 8.79655 17.3144 8.79655 17.108C8.79655 16.9015 8.75583 16.7197 8.67439 16.5625C8.59484 16.4053 8.47079 16.2831 8.30223 16.196C8.13556 16.107 7.9206 16.0625 7.65734 16.0625H6.69143V21H5.63746ZM12.8775 21H10.9059V15.1818H12.9173C13.4949 15.1818 13.9911 15.2983 14.4059 15.5312C14.8226 15.7623 15.1426 16.0947 15.3661 16.5284C15.5896 16.9621 15.7013 17.4811 15.7013 18.0852C15.7013 18.6913 15.5887 19.2121 15.3633 19.6477C15.1398 20.0833 14.8169 20.4176 14.3945 20.6506C13.9741 20.8835 13.4684 21 12.8775 21ZM11.9599 20.0881H12.8263C13.2317 20.0881 13.5697 20.0142 13.8406 19.8665C14.1114 19.7169 14.315 19.4943 14.4513 19.1989C14.5877 18.9015 14.6559 18.5303 14.6559 18.0852C14.6559 17.6402 14.5877 17.2708 14.4513 16.9773C14.315 16.6818 14.1133 16.4612 13.8462 16.3153C13.5811 16.1676 13.2515 16.0938 12.8576 16.0938H11.9599V20.0881ZM16.8228 21V15.1818H20.55V16.0653H17.8767V17.6449H20.2944V18.5284H17.8767V21H16.8228Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_372_3651"
          x="1"
          y="0"
          width="25"
          height="29"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_372_3651" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_372_3651" result="shape" />
        </filter>
        <filter
          id="filter1_d_372_3651"
          x="5.63745"
          y="15.1816"
          width="16.9126"
          height="7.81836"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.51 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_372_3651" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_372_3651" result="shape" />
        </filter>
      </defs>
    </SvgIcon>
  );
}
