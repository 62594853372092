import { Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';

type OwnerState = { transparentNavbar: boolean };

export function navbar(theme: Theme, { transparentNavbar }: OwnerState): SystemStyleObject {
  const { palette, boxShadows, functions, transitions, breakpoints, borders } = theme;

  const { linearGradient, pxToRem } = functions;

  return {
    boxShadow: transparentNavbar ? 'none' : boxShadows.navbarBoxShadow,
    backdropFilter: transparentNavbar ? 'none' : `blur(${pxToRem(42)})`,
    backgroundColor: `${palette.transparent.rgba}`,
    backgroundImage: transparentNavbar
      ? `none`
      : `${linearGradient(
          palette.gradients.navbar.main,
          palette.gradients.navbar.state,
          palette.gradients.navbar.deg,
        )}`,

    color: () => {
      let color = palette.white.main;

      if (transparentNavbar) {
        color = palette.text.main;
      }

      return color;
    },
    top: pxToRem(12),
    minHeight: pxToRem(75),
    display: 'grid',
    alignItems: 'center',

    borderRadius: borders.borderRadius.xl,
    borderColor: transparentNavbar ? `${palette.transparent.main}` : `${palette.borderColor.white}`,
    paddingTop: pxToRem(8),
    paddingBottom: pxToRem(8),
    paddingRight: 0,
    paddingLeft: 0,

    '& > *': {
      transition: transitions.create('all', {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    '& .MuiToolbar-root': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      [breakpoints.up('sm')]: {
        minHeight: 'auto',
        padding: `${pxToRem(4)} ${pxToRem(16)}`,
      },
    },
  };
}

export const navbarRow = ({ palette: { white } }: Theme): SystemStyleObject => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'stretch',
  width: 'max-content',
  '&.MuiBox-root': {
    '& nav': {
      '& ol': {
        '& li': {
          '&.MuiBreadcrumbs-li': {
            '& a': {
              '& span': {
                color: white.main,
              },
            },
          },
          '&.MuiBreadcrumbs-li span.MuiTypography-button': {
            color: white.main,
          },
          '&.MuiBreadcrumbs-separator': {
            color: white.main,
          },
        },
      },
    },
  },
  // '& h6': {
  //   color: 'rgb(255,255,255)',
  // },

  // [breakpoints.up('md')]: {
  //   justifyContent: 'stretch',
  //   width: 'max-content',
  // },

  // [breakpoints.up('lg')]: {
  //   justifyContent: 'stretch !important',
  //   width: 'max-content !important',
  // },
});

export const navbarIconButton = ({
  typography: { size },
  breakpoints,
  palette: { white },
}: Theme): SystemStyleObject => ({
  px: 0.75,

  '& .material-icons, .material-icons-round': {
    fontSize: `${size.md} !important`,
    color: white.main,
  },

  '& .MuiTypography-root': {
    display: 'none',
    color: white.main,

    [breakpoints.up('sm')]: {
      display: 'inline-block',
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const navbarMobileMenu = ({ palette }: Theme): SystemStyleObject => ({
  display: 'inline-block',
  lineHeight: 0,
  color: palette.white.main,
});
