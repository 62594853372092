import { Theme } from '@mui/material';

import { ViewState } from '../../../../theme';

type OwnState = {
  active?: boolean;
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
  miniSidenav?: ViewState['miniSidenav'];
};

export function collapseItem(theme: Theme, ownerState: OwnState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
  const { active } = ownerState;
  const { pxToRem } = functions;

  return {
    margin: `${pxToRem(5)} ${pxToRem(12.8)} ${pxToRem(5)} ${pxToRem(16)}`,
    padding: `${pxToRem(10.8)} ${pxToRem(12.8)} ${pxToRem(10.8)} ${pxToRem(18)}`,
    background: active ? palette.sidenav.button : palette.transparent.main,
    color: palette.white.main,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: borders.borderRadius.lg,
    cursor: 'pointer',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    boxShadow: active ? boxShadows.xxl : 'none',
    '&:hover': {
      background: palette.sidenav.button,
    },
    [breakpoints.up('lg')]: {
      boxShadow: 'none',
      transition: transitions.create('box-shadow', {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },
  };
}

export function collapseIconBox(theme: Theme, ownerState: OwnState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
  const { active, color } = ownerState;

  const { pxToRem } = functions;

  return {
    background: palette.sidenav.button,
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    borderRadius: borders.borderRadius.button,
    display: 'grid',
    placeItems: 'center',
    boxShadow: boxShadows.md,
    transition: transitions.create('margin', {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    [breakpoints.up('lg')]: {
      background: () => {
        let background;

        if (!active) {
          background = palette.sidenav.button;
        } else if (color === 'warning') {
          background = palette.gradients.warning.main;
        } else {
          // @ts-expect-error: noop
          background = palette[color].main;
        }

        return background;
      },
    },

    // @ts-expect-error: noop
    backgroundColor: active ? palette[color].main : palette.transparent.main,
    '& svg, svg g': {
      // @ts-expect-error: noop
      fill: active ? palette.white.main : palette[color].main,
    },
  };
}

export const collapseIcon = ({ palette: { white, gradients } }: Theme, { active }: OwnState) => ({
  color: active ? white.main : gradients.dark.state,
});

export function collapseText(theme: Theme, ownerState: OwnState) {
  const { typography, transitions, breakpoints, functions } = theme;
  const { miniSidenav, active } = ownerState;

  const { size, fontWeightMedium, fontWeightRegular } = typography;
  const { pxToRem } = functions;

  return {
    marginLeft: pxToRem(12.8),
    [breakpoints.up('lg')]: {
      opacity: miniSidenav ? 0 : 1,
      maxWidth: miniSidenav ? 0 : '100%',
      marginLeft: miniSidenav ? 0 : pxToRem(12.8),
      transition: transitions.create(['opacity', 'margin'], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },
    '& span': {
      fontWeight: active ? fontWeightMedium : fontWeightRegular,
      fontSize: size.sm,
      lineHeight: 0,
    },
  };
}
