export const filenameWithoutLoaders = (filename = '') => {
  const index = filename.lastIndexOf('!');

  return index < 0 ? filename : filename.slice(index + 1);
};

export const filenameHasLoaders = (filename: string) => {
  const actualFilename = filenameWithoutLoaders(filename);

  return actualFilename !== filename;
};

export const filenameHasSchema = (filename: string) => /^\w+:/.test(filename);

export const isFilenameAbsolute = (filename: string) => {
  const actualFilename = filenameWithoutLoaders(filename);

  return actualFilename.indexOf('/') === 0;
};

export const makeUrl = (filename: string, scheme: string, line?: number, column?: number) => {
  const actualFilename = filenameWithoutLoaders(filename);

  if (filenameHasSchema(filename)) {
    return actualFilename;
  }

  let url = `file://${actualFilename}`;

  if (scheme === 'vscode') {
    url = `${scheme}://file/${url}`;
    url = url.replace(/file:\/{3}/, ''); // visual studio code does not need file:/// in its scheme
    if (line && actualFilename === filename) {
      url = `${url}:${line}`;

      if (column) {
        url = `${url}:${column}`;
      }
    }
  } else if (scheme) {
    url = `${scheme}://open?url=${url}`;

    if (line && actualFilename === filename) {
      url = `${url}&line=${line}`;

      if (column) {
        url = `${url}&column=${column}`;
      }
    }
  }

  return url;
};

export const makeLinkText = (filename: string, line?: number, column?: number) => {
  let text = filenameWithoutLoaders(filename);

  if (line && text === filename) {
    text = `${text}:${line}`;

    if (column) {
      text = `${text}:${column}`;
    }
  }

  return text;
};
