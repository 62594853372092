import React from 'react';
import { GraphQLErrors } from '@apollo/client/errors';
import { FormikErrors, FormikHelpers, useFormik } from 'formik';
import { FieldHelperProps, FieldInputProps, FieldMetaProps, FormikTouched, FormikValues } from 'formik/dist/types';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';

import { isFormError } from '../errors/FormError';

export type FormikInstance<Values> = {
  initialValues: Values;
  initialErrors: FormikErrors<unknown>;
  initialTouched: FormikTouched<unknown>;
  initialStatus: any;
  handleBlur: {
    (e: React.FocusEvent<any>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  handleReset: (e: any) => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  setErrors: (errors: FormikErrors<Values>) => void;
  setFieldTouched: (
    field: string,
    touched?: boolean,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<Values>> | Promise<void>;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<Values>> | Promise<void>;
  setFieldError: (field: string, value: string | undefined) => void;
  setStatus: (status: any) => void;
  setSubmitting: (isSubmitting: boolean) => void;
  setTouched: (
    touched: FormikTouched<Values>,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<Values>> | Promise<void>;
  setValues: (
    values: React.SetStateAction<Values>,
    shouldValidate?: boolean | undefined,
  ) => Promise<FormikErrors<Values>> | Promise<void>;
  submitForm: () => Promise<any>;
  validateField: (name: string) => Promise<void> | Promise<string | undefined>;
  isValid: boolean;
  dirty: boolean;
  unregisterField: (name: string) => void;
  registerField: (name: string, { validate }: any) => void;
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  getFieldMeta: (name: string) => FieldMetaProps<any>;
  getFieldHelpers: (name: string) => FieldHelperProps<any>;
  validateOnBlur: boolean;
  validateOnChange: boolean;
  validateOnMount: boolean;
  values: Values;
  errors: FormikErrors<Values> & { errorMsg?: string };
  touched: FormikTouched<Values>;
  isSubmitting: boolean;
  isValidating: boolean;
  status?: any;
  submitCount: number;
};

type SubmitError<Values> = {
  errors: FormikErrors<Values> & { errorMsg?: string; extensions?: { errors: GraphQLErrors } & Record<string, any> };
  message: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal;
};

type Params<Values, Schema> = {
  initialValues: Values;
  validationSchema: Schema;
  onSubmit: (val: Values, formikHelpers: FormikHelpers<Values>) => Promise<unknown>;
  successMessage?: string;
  isResetFormAfterSubmit?: boolean;
};

export function useForm<Values extends FormikValues, Schema>(params: Params<Values, Schema>): FormikInstance<Values> {
  const { initialValues, validationSchema, successMessage, onSubmit, isResetFormAfterSubmit } = params;
  const { enqueueSnackbar } = useSnackbar();

  return useFormik({
    initialValues,
    validationSchema,
    onSubmit: (val, formikHelpers) => {
      onSubmit(val, formikHelpers)
        .then(() => {
          formikHelpers.setSubmitting(false);

          if (successMessage) {
            enqueueSnackbar(successMessage, { variant: 'success' });
          }

          if (isResetFormAfterSubmit) {
            formikHelpers.resetForm();
          }

          return null;
        })
        .catch((error: SubmitError<Values>) => {
          formikHelpers.setSubmitting(false);

          if (isFormError(error)) {
            formikHelpers.setErrors(error.errors);

            if (error.errors?.errorMsg) {
              if (!isEmpty(error.errors?.extensions?.errors)) {
                const graphQLErrors = error.errors.extensions?.errors || {};

                const errorMessages = Object.entries(graphQLErrors).map((item) => item[1]);
                const errors = [error.errors.errorMsg, ...errorMessages];
                // @ts-expect-error: noop
                enqueueSnackbar(errors, { variant: 'error' });
              } else {
                enqueueSnackbar(error.errors.errorMsg, { variant: 'error' });
              }
            }
          } else {
            enqueueSnackbar(error.message || 'Unknown form error', { variant: 'error' });
          }
        });
    },
  });
}
