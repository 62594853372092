import React from 'react';
import { Theme } from '@mui/material';

import { ViewState } from '../../theme';
import { MdBox } from '../override-components/MdBox';

type Props = { miniSidenav: ViewState['miniSidenav'] };

export function PageLayout({ miniSidenav, children }: React.PropsWithChildren<Props>) {
  return (
    <MdBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }: Theme) => {
        const marginLeftSize = miniSidenav ? pxToRem(120) : pxToRem(324);

        return {
          width: '100%',
          padding: 2,
          position: 'relative',
          [breakpoints.up('lg')]: {
            marginLeft: marginLeftSize,
            width: `calc(100% - ${marginLeftSize})`,
            transition: transitions.create(['margin-left', 'margin-right'], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        };
      }}
    >
      {children}
    </MdBox>
  );
}
