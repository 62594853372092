import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

import { MdTypography } from '../override-components/MdTypography';

export function ServerError() {
  const { t } = useTranslation();

  return (
    <Container sx={{ height: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Paper elevation={0} sx={{ background: 'transparent', paddingBottom: '300px' }}>
        <Stack alignItems="center" spacing={3}>
          <MdTypography sx={{ my: 3 }} variant="h2">
            {/* TODO translation */}
            {t('Server error')}
          </MdTypography>
          {/* TODO translation */}
          <Button onClick={() => window.location.reload()}>{t('Reload page')}</Button>
        </Stack>
      </Paper>
    </Container>
  );
}
