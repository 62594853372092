/* eslint-disable react/require-default-props */
import React, { useEffect, useMemo, useState } from 'react';
import { CSSTransition, SwitchTransition, TransitionGroup } from 'react-transition-group';

import './styles.scss';

const CARDS = {
  visa: '^4',
  amex: '^(34|37)',
  mastercard: '^5[1-5]',
  discover: '^6011',
  unionpay: '^62',
  troy: '^9792',
  diners: '^(30[0-5]|36)',
};

const cardBackgroundName = () => {
  const random = Math.floor(Math.random() * 25 + 1);
  return `${random}.jpeg`;
};

const BACKGROUND_IMG = cardBackgroundName();

type Props = {
  cardNumberRef?: React.RefObject<HTMLLabelElement>;
  cardHolderRef?: React.RefObject<HTMLLabelElement>;
  cardDateRef?: React.MutableRefObject<HTMLDivElement>;
  cardCvvRef?: React.MutableRefObject<HTMLDivElement>;
  currentFocusedElm?: React.MutableRefObject<HTMLLabelElement | HTMLDivElement>;
  cardType?: string;
  cardHolder?: string;
  cardNumber: string;
  cardMonth: string;
  cardYear: string;
  isCardFlipped?: boolean;
  onCardElementClick?: (arg: string) => void;
};

const noop = () => {};

export function CreditCard(props: Props) {
  const {
    cardType,
    cardHolder = 'FULL NAME',
    cardNumber,
    cardMonth,
    cardYear,
    isCardFlipped = false,
    currentFocusedElm = null,
    onCardElementClick = noop,
    cardNumberRef = null,
    cardHolderRef = null,
    cardDateRef = null,
    cardCvvRef = null,
  } = props;

  const [style, setStyle] = useState({});

  const handleCardType = (cardNum: string) => {
    const number = cardNum;
    let re;

    for (const [card, pattern] of Object.entries(CARDS)) {
      re = new RegExp(pattern);
      if (number.match(re) != null) {
        return card;
      }
    }

    return 'visa'; // default type
  };

  const getCardType = useMemo(() => cardType || handleCardType(cardNumber), [cardNumber, cardType]);

  const outlineElementStyle = (element: HTMLElement) =>
    element
      ? {
          width: '100%',
          height: 'auto',
        }
      : null;

  useEffect(() => {
    if (currentFocusedElm) {
      const stl = outlineElementStyle(currentFocusedElm.current);
      // @ts-expect-error: noop
      setStyle(stl);
    }
  }, [currentFocusedElm]);

  const maskCardNumber = (cardNum: string) => {
    const cardNumberArr = [...cardNum];
    cardNumberArr.forEach((_val, index) => {
      if (index > 4 && index < 14 && cardNumberArr[index] !== ' ') {
        cardNumberArr[index] = '*';
      }
    });

    return cardNumberArr;
  };

  return (
    <div className={`card-item ${isCardFlipped ? '-active' : ''}`} style={{ transform: 'scale(0.5)' }}>
      <div className={`card-item__focus ${currentFocusedElm ? `-active` : ``}`} style={style} />
      <div className="card-item__side -front">
        <div className="card-item__cover">
          <img alt="" src={`/card-background/${BACKGROUND_IMG}`} className="card-item__bg" />
        </div>

        <div className="card-item__wrapper">
          <div className="card-item__top">
            <img src="/chip.png" alt="" className="card-item__chip" />
            <div className="card-item__type">
              <img alt={getCardType} src={`/card-type/${getCardType}.png`} className="card-item__typeImg" />
            </div>
          </div>

          <label className="card-item__number" ref={cardNumberRef} onClick={() => onCardElementClick('cardNumber')}>
            <TransitionGroup className="slide-fade-up" component="div">
              {cardNumber ? (
                maskCardNumber(cardNumber).map((val, index) => (
                  <CSSTransition classNames="slide-fade-up" timeout={250} key={index}>
                    <div className="card-item__numberItem">{val}</div>
                  </CSSTransition>
                ))
              ) : (
                <CSSTransition classNames="slide-fade-up" timeout={250}>
                  <div className="card-item__numberItem">#</div>
                </CSSTransition>
              )}
            </TransitionGroup>
          </label>
          <div className="card-item__content">
            <label className="card-item__info" onClick={() => onCardElementClick('cardHolder')} ref={cardHolderRef}>
              <div className="card-item__holder">Card Holder</div>
              <div className="card-item__name">
                <TransitionGroup component="div" className="slide-fade-up">
                  {cardHolder === 'FULL NAME' ? (
                    <CSSTransition classNames="slide-fade-up" timeout={250}>
                      <div>FULL NAME</div>
                    </CSSTransition>
                  ) : (
                    cardHolder.split('').map((val, index) => (
                      <CSSTransition timeout={250} classNames="slide-fade-right" key={index}>
                        <span className="card-item__nameItem">{val}</span>
                      </CSSTransition>
                    ))
                  )}
                </TransitionGroup>
              </div>
            </label>
            <div className="card-item__date" onClick={() => onCardElementClick('cardDate')} ref={cardDateRef}>
              <label className="card-item__dateTitle">Expires</label>
              <label className="card-item__dateItem">
                <SwitchTransition in-out>
                  <CSSTransition classNames="slide-fade-up" timeout={200} key={cardMonth}>
                    <span>{!cardMonth ? 'MM' : cardMonth} </span>
                  </CSSTransition>
                </SwitchTransition>
              </label>
              /
              <label htmlFor="cardYear" className="card-item__dateItem">
                <SwitchTransition out-in>
                  <CSSTransition classNames="slide-fade-up" timeout={250} key={cardYear}>
                    <span>{!cardYear ? 'YY' : cardYear.toString().slice(-2)}</span>
                  </CSSTransition>
                </SwitchTransition>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="card-item__side -back">
        <div className="card-item__cover">
          <img alt="" src={`/card-background/${BACKGROUND_IMG}`} className="card-item__bg" />
        </div>
        <div className="card-item__band" />
        <div className="card-item__cvv" ref={cardCvvRef}>
          <div className="card-item__cvvTitle">CVV</div>
          <div className="card-item__cvvBand">
            {['***'].map((value, idx) => (
              <span key={`${idx + 1}`}>{value}</span>
            ))}
          </div>
          <div className="card-item__type">
            <img alt={getCardType} src={`/card-type/${getCardType}.png`} className="card-item__typeImg" />
          </div>
        </div>
      </div>
    </div>
  );
}
