import React from 'react';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import linearProgressClasses from '@mui/material/LinearProgress/linearProgressClasses';
import Stack from '@mui/material/Stack';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

export function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  const { value } = props;

  return (
    <Stack direction="row" sx={{ width: '100%', alignItems: 'center' }}>
      <BorderLinearProgress variant="determinate" {...props} sx={{ width: '100%', mr: 1 }} />
      <Typography sx={{ minWidth: 35 }} variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
    </Stack>
  );
}
