import { isApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export class FormError extends Error {
  private readonly err: Record<string, any>;

  constructor(errorMsg: string, err?: Error) {
    super(errorMsg);

    if (err) {
      if (isApolloError(err)) {
        if (err.networkError) {
          throw err;
        } else {
          // eslint-disable-next-line unicorn/no-array-reduce
          const graphQLErrors = err.graphQLErrors.reduce(
            // @ts-expect-error: TODO need fix this
            (acc: GraphQLError, item: GraphQLError) => ({ ...acc, ...item }),
            new GraphQLError(errorMsg),
          );

          this.err = { ...graphQLErrors, errorMsg };
        }
      } else {
        throw err;
      }
    } else {
      this.err = { errorMsg };
    }
  }

  get errors() {
    return this.err;
  }
}

export const isFormError = (err: any) => err instanceof FormError;
