import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

export function FormItem({ children, label, htmlFor, ...props }: DefaultComponentProps<any>) {
  return (
    <FormControl {...props}>
      {label && <InputLabel htmlFor={htmlFor}>{label}</InputLabel>}
      {children}
    </FormControl>
  );
}
