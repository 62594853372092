import React from 'react';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

import { TextField } from './TextField';

export function Select({ children, ...props }: DefaultComponentProps<any>) {
  return (
    <TextField select size="small" {...props}>
      {children}
    </TextField>
  );
}
