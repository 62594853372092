import React from 'react';
import Box from '@mui/material/Box';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

export const Form = React.forwardRef<HTMLFormElement, DefaultComponentProps<any>>(({ children, ...props }, ref) => (
  <Box
    ref={ref}
    component="form"
    sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    noValidate
    autoComplete="off"
    {...props}
  >
    {children}
  </Box>
));
