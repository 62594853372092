import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

type Props = {
  serverPort: number;
};

export function SseProvider({ serverPort }: Props) {
  const { logout } = useAuth0();

  React.useEffect(() => {
    let sse: EventSource;

    (async () => {
      // @ts-expect-error: noop
      const port = process.env.IS_DEV ? `:${serverPort}` : '';
      const sseGateway = `${window.location.protocol}//${window.location.hostname}${port}/api/user/events`;

      sse = new EventSource(sseGateway, { withCredentials: true });
      sse.addEventListener('message', (event) => {
        let data;

        try {
          data = JSON.parse(event.data);
        } catch (error) {
          console.error(error);
        }

        if (data.type === 'LOGOUT') {
          logout({ returnTo: window.location.origin });
        }
      });
    })();

    return () => {
      if (sse && typeof sse.close === 'function') {
        sse.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}
