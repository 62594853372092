import React from 'react';
import { BoxProps } from '@mui/material/Box';

import { MdBoxRoot } from './MdBoxRoot';

type AvailableColors =
  | 'transparent'
  | 'white'
  | 'black'
  | 'primary'
  | 'secondary'
  | 'info'
  | 'success'
  | 'warning'
  | 'error'
  | 'light'
  | 'dark'
  | 'text'
  | 'grey-100'
  | 'grey-200'
  | 'grey-300'
  | 'grey-400'
  | 'grey-500'
  | 'grey-600'
  | 'grey-700'
  | 'grey-800'
  | 'grey-900';

type Props = {
  bgColor?: AvailableColors;
  color?: AvailableColors;
  opacity?: number;
  borderRadius?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'section' | 'none';
  variant?: 'contained' | 'gradient';
  shadow?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'inset' | 'none';
};

export const MdBox = React.forwardRef<HTMLDivElement, BoxProps<any, Props>>((props, ref) => {
  const {
    variant = 'contained',
    bgColor = 'transparent',
    color = 'dark',
    opacity = 1,
    borderRadius = 'none',
    shadow = 'none',
    ...rest
  } = props;

  return <MdBoxRoot {...rest} ref={ref} ownerState={{ variant, bgColor, color, opacity, borderRadius, shadow }} />;
});
