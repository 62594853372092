import React from 'react';
import { InputBaseComponentProps, Theme } from '@mui/material';
import useTheme from '@mui/styles/useTheme';
import { alpha } from '@mui/system';

export const StripeInput = React.forwardRef<any, InputBaseComponentProps>((props, ref) => {
  const { component: Component, options, ...other } = props;
  const theme = useTheme<Theme>();
  const [mountNode, setMountNode] = React.useState<any | null>(null);

  React.useImperativeHandle(
    ref,
    () => ({
      focus: () => {
        mountNode.focus();
      },
    }),
    [mountNode],
  );

  React.useLayoutEffect(() => {
    if (mountNode && props.autoFocus) {
      mountNode.focus();
    }
  }, [mountNode, props.autoFocus]);

  return (
    <Component
      onReady={setMountNode}
      options={{
        ...options,
        style: {
          base: {
            color: theme.palette.text.primary,
            fontSize: '16px',
            lineHeight: '1.4375em', // 23px
            fontFamily: theme.typography.fontFamily,
            '::placeholder': {
              color: alpha(theme.palette.text.primary, 0.42),
            },
          },
          invalid: {
            color: theme.palette.text.primary,
          },
        },
      }}
      {...other}
    />
  );
});
