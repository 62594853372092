import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';

import { withDefaultProps } from '../_shared/withDefaultProps';

export const DateRangeDelimiter = withDefaultProps(
  { name: 'MuiPickersDateRangeDelimiter' },
  styled(Typography)({
    margin: '0 16px',
  }),
);

export type DateRangeDelimiterProps = React.ComponentProps<typeof DateRangeDelimiter>;
