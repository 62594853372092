import React from 'react';
import { Theme } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import Icon from '@mui/material/Icon';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';

import { ViewState } from '../../../theme';
import { MdBox } from '../../override-components/MdBox';

import { collapseIcon, collapseIconBox, collapseItem, collapseText } from './styles/sidenavCollapse';

type Props = {
  icon: React.ReactNode;
  name: React.ReactNode;
  miniSidenav: ViewState['miniSidenav'];
  color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
  active?: boolean;
  open?: boolean;
};

export function SidenavCollapse(props: React.PropsWithChildren<Props>) {
  const { icon, name, miniSidenav, color = 'info', active = false, open = false, children } = props;

  return (
    <>
      <Tooltip arrow disableInteractive title={name} placement="right">
        <ListItem component="li">
          <MdBox sx={(theme: Theme) => collapseItem(theme, { color, active })}>
            <ListItemIcon sx={(theme: Theme) => collapseIconBox(theme, { active, color })}>
              {typeof icon === 'string' ? (
                <Icon sx={(theme) => collapseIcon(theme, { color, active })}>{icon}</Icon>
              ) : (
                icon
              )}
            </ListItemIcon>
            <ListItemText primary={name} sx={(theme) => collapseText(theme, { color, miniSidenav, active })} />
          </MdBox>
        </ListItem>
      </Tooltip>
      {children && (
        <Collapse in={open} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}
