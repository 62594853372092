import React from 'react';
import MaterialCollapse from '@mui/material/Collapse';
import { DefaultComponentProps } from '@mui/material/OverridableComponent';

export function Collapse({ children, color, ...props }: DefaultComponentProps<any>) {
  return (
    <MaterialCollapse {...props} severity={color}>
      {children}
    </MaterialCollapse>
  );
}
