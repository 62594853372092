export type AsyncPipeMapper<T, U> = (data: T | U) => Promise<U>;
export type AsyncPipeReducer<T, U> = (f: AsyncPipeMapper<T, U>, g: AsyncPipeMapper<T, U>) => AsyncPipeMapper<T, U>;
export type AsyncPipe<T, U> = (...fns: any[]) => AsyncPipeMapper<T, U>;

export function createAsyncPipe<T, U>(): AsyncPipe<T, U> {
  // @ts-ignore
  const asyncPipe: AsyncPipeReducer<T, U> = (f: AsyncPipeMapper<T, U>, g: AsyncPipeMapper<T, U>) => async (data: T) =>
    g(await f(data));
  return (...fns: AsyncPipeMapper<T, U>[]): AsyncPipeMapper<T, U> => fns.reduce(asyncPipe);
}
