import React from 'react';
import MuiMarkdown from 'mui-markdown';
import theme from 'prism-react-renderer/themes/vsDark';

// import { NavLink } from '../../material/NavLink';

export function Markdown({ children }: any) {
  return (
    <MuiMarkdown
      codeBlockTheme={theme}
      // options={{
      //   overrides: {
      //     a: NavLink,
      //   },
      // }}
    >
      {children}
    </MuiMarkdown>
  );
}
