import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { TypographyProps } from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import { ExtendMui } from '../typings/helpers';

import ToolbarText from './ToolbarText';

export interface ToolbarButtonProps extends ExtendMui<ButtonProps, 'value' | 'variant'> {
  align?: TypographyProps['align'];
  selected: boolean;
  typographyClassName?: string;
  value: React.ReactNode;
  variant: TypographyProps['variant'];
}

export const useStyles = makeStyles(
  {
    root: {
      padding: 0,
      minWidth: '16px',
      textTransform: 'none',
    },
  },
  { name: 'MuiPickersToolbarButton' },
);

export const ToolbarButton: React.FunctionComponent<ToolbarButtonProps> = (props) => {
  const { align, className, selected, typographyClassName, value, variant, ...other } = props;
  const classes = useStyles();

  return (
    <Button data-mui-test="toolbar-button" variant="text" className={clsx(classes.root, className)} {...other}>
      <ToolbarText align={align} className={typographyClassName} variant={variant} value={value} selected={selected} />
    </Button>
  );
};

ToolbarButton.displayName = 'ToolbarButton';

export default ToolbarButton;
