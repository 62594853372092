import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import * as i18next from 'i18next';

import { TextField } from './TextField';

type Props = {
  i18n: i18next.i18n;
};

export function LanguagePicker(props: Props) {
  const { i18n } = props;

  const languages = Object.keys(i18n.store.data);
  const [lang, setLang] = React.useState(i18n.language.slice(0, 2).toUpperCase());

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLang(event.target.value);
  };

  return (
    <section>
      {i18n.language && languages.length > 1 && (
        <TextField select value={lang} size="small" onChange={handleChange}>
          {languages.map((lng) => (
            <MenuItem key={lng} value={lng.slice(0, 2).toUpperCase()} onClick={() => i18n.changeLanguage(lng)}>
              {lng.slice(0, 2).toUpperCase()}
            </MenuItem>
          ))}
        </TextField>
      )}
    </section>
  );
}
