import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { SxProps } from '@mui/system';

export function LoadingBackdrop({ loading, backdropSx }: { loading: boolean; backdropSx?: SxProps }) {
  return (
    <Backdrop
      unmountOnExit
      open={loading}
      transitionDuration={800}
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, position: 'absolute', ...backdropSx }}
    >
      <CircularProgress color="primary" />
    </Backdrop>
  );
}
