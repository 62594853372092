const dark = {
  main: '#344767',
  focus: '#121241',
  body: '#030c1d',
};
const charts = {
  100: '#2d8cfc',
  200: '#2370cc',
  300: '#2370cc',
  400: '#0f4a91',
  500: '#073a78',
  600: '#012654',
};
const gradients = {
  navbar: {
    deg: '123.64deg',
    main: 'rgba(255, 255, 255, 0) -22.38%',
    state: 'rgba(255, 255, 255, 0.039) 70.38%',
  },
  sidenav: {
    deg: '127.09',
    main: 'rgba(6, 11, 40, 0.94) 19.41%',
    state: 'rgba(10, 14, 35, 0.49) 76.65%',
  },
  borderLight: {
    angle: '94.43% 69.43% at 50% 50%',
    main: '#ffffff 0%',
    state: 'rgba(255, 255, 255, 0) 100%',
  },
  borderDark: {
    angle: '69.43% 69.43% at 50% 50%',
    main: '#ffffff 0%',
    state: 'rgba(255, 255, 255, 0) 100%',
  },
  cover: {
    deg: '159.02',
    main: '#0f123b 14.25%',
    state: '#090d2e 56.45%',
    stateSecondary: '#020515 86.14%',
  },
  cardDark: {
    deg: '126.97',
    main: 'rgba(6, 11, 40, 0.74) 28.26%',
    state: 'rgba(10, 14, 35, 0.71) 91.2%',
  },
  cardLight: {
    deg: '127.09',
    main: 'rgba(6, 11, 40, 0.94) 19.41%',
    state: 'rgba(10, 14, 35, 0.49) 76.65%',
  },
  card: {
    deg: '127.09',
    main: 'rgba(6, 11, 40, 0.94) 19.41%',
    state: 'rgba(10, 14, 35, 0.49) 76.65%',
  },
  menu: {
    deg: '126.97',
    main: '#05153f 28.26%',
    state: '#072561 91.2%',
  },
  cardContent: {
    deg: '126.97',
    main: 'rgb(6, 11, 40) 28.26%',
    state: 'rgb(10, 14, 35) 91.2%',
  },
  box: {
    deg: '126.97',
    main: 'rgba(6, 11, 40, 0.74) 28.26%',
    state: 'rgba(10, 14, 35, 0.71) 91.2%',
  },
  bill: {
    deg: '127.09',
    main: 'rgba(24, 29, 60, 0.94) 19.41%',
    state: 'rgba(10, 14, 35, 0.49) 76.65%',
  },
  primary: {
    deg: '97.89',
    main: '#4318ff',
    state: '#9f7aea',
  },
  secondary: {
    main: '#627594',
    state: '#a8b8d8',
  },
  logo: {
    deg: '97.89',
    main: '#ffffff 70.67%',
    state: 'rgba(117, 122, 140, 0) 108.55%',
  },
  info: {
    main: '#0075ff',
    state: '#21d4fd',
  },
  success: {
    main: '#01B574',
    state: '#c9fbd5',
  },
  warning: {
    main: '#f53939',
    state: '#fbcf33',
  },
  error: {
    main: '#f53c2b',
    state: '#f53c2b',
  },
  light: {
    main: '#ced4da',
    state: '#ebeff4',
  },
  dark: {
    main: '#141727',
    state: '#3a416f',
  },
};

export const palette = {
  /*
   * Base scheme
   * */
  primary: {
    light: 'rgb(51, 144, 255)',
    main: '#0075ff',
    dark: 'rgb(0, 81, 178)',
    contrastText: '#ffffff',
  },
  secondary: {
    light: 'rgb(63, 67, 93)',
    main: '#0f1535',
    dark: 'rgb(10, 14, 37)',
    contrastText: '#ffffff',
  },
  error: {
    light: 'rgb(232, 71, 71)',
    main: '#e31a1a',
    dark: 'rgb(158, 18, 18)',
    contrastText: '#ffffff',
  },
  warning: {
    light: 'rgb(255, 195, 107)',
    main: '#ffb547',
    dark: 'rgb(178, 126, 49)',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  info: {
    light: 'rgb(51, 144, 255)',
    main: '#0075ff',
    dark: 'rgb(0, 81, 178)',
    contrastText: '#ffffff',
  },
  success: {
    light: 'rgb(51, 195, 143)',
    main: '#01b574',
    dark: 'rgb(0, 126, 81)',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  grey: {
    100: '#edf2f7',
    200: '#e2e8f0',
    300: '#cbd5e0',
    400: '#a0aec0',
    500: '#718096',
    600: '#4a5568',
    700: '#2d3748',
    800: '#1a202a',
    900: '#171923',
  },
  text: {
    primary: '#ffffff',
    secondary: 'rgba(255, 255, 255, 0.7)',
    disabled: 'rgba(255, 255, 255, 0.5)',
  },
  divider: '#344767',
  // action: {
  //   active: '',
  //   hover: '',
  //   hoverOpacity: 0,
  //   selected: '',
  //   selectedOpacity: 0,
  //   disabled: '',
  //   disabledOpacity: 0,
  //   disabledBackground: '',
  //   focus: '',
  //   focusOpacity: 0,
  //   activatedOpacity: 0,
  // },
  background: {
    default: dark.body,
    paper: '#0f1535',
  },

  /*
   * Custom colors
   * */
  sidenav: {
    button: '#1a1f37',
  },
  transparent: {
    main: 'transparent',
    focus: 'transparent',
    rgba: 'rgba(255, 255, 255, 0)',
  },
  white: {
    main: '#ffffff',
    focus: '#ffffff',
  },
  brand: {
    main: '#0075ff',
    focus: '#0075ff',
  },
  black: {
    light: '#141414',
    main: '#000000',
    focus: '#000000',
  },
  lightblue: {
    main: '#4299e1',
    focus: '#4299e1',
  },
  orange: {
    main: '#f6ad55',
    focus: '#f6ad55',
  },
  borderColor: {
    main: '#56577a',
    red: '#e31a1a',
    white: 'rgba(226, 232, 240, 0.3)',
  },
  light: {
    main: '#e9ecef',
    focus: '#ebeff4',
  },
  alertColors: {
    primary: {
      main: '#7928ca',
      state: '#d6006c',
      border: '#efb6e2',
    },
    secondary: {
      main: '#627594',
      state: '#8ca1cb',
      border: '#dadee6',
    },
    info: {
      main: '#2152ff',
      state: '#02c6f3',
      border: '#b9ecf8',
    },
    success: {
      main: '#17ad37',
      state: '#84dc14',
      border: '#daf3b9',
    },
    warning: {
      main: '#f53939',
      state: '#fac60b',
      border: '#fef1c2',
    },
    error: {
      main: '#ea0606',
      state: '#ff3d59',
      border: '#f9b4b4',
    },
    light: {
      main: '#ced4da',
      state: '#d1dae6',
      border: '#f8f9fa',
    },
    dark: {
      main: '#141727',
      state: '#2c3154',
      border: '#c2c8d1',
    },
  },
  badgeColors: {
    primary: {
      basic: '#805ad5',
      background: '#f883dd',
      text: '#a3017e',
    },
    secondary: {
      basic: '#5974a2',
      background: '#e4e8ed',
      text: '#5974a2',
    },
    info: {
      basic: '#4299e1',
      background: '#abe9f7',
      text: '#08a1c4',
    },
    success: {
      basic: '#01b574',
      background: '#c9fbd5',
      text: '#01b574',
    },
    warning: {
      basic: '#ffb547',
      background: '#fef5d3',
      text: '#fbc400',
    },
    error: {
      basic: '#e31a1a',
      background: '#fc9797',
      text: '#bd0000',
    },
    light: {
      basic: '#ffffff',
      background: '#ffffff',
      text: '#c7d3de',
    },
    dark: {
      basic: '#1E244B',
      background: '#1E244B',
      text: '#fff',
    },
  },
  inputColors: {
    backgroundColor: '#0f1535',
    borderColor: {
      main: 'rgba(226, 232, 240, 0.3)',
      focus: 'rgba(226, 232, 240, 0.6)',
    },
    boxShadow: '#628fc2',
    error: '#fd5c70',
    success: '#66d432',
  },
  sliderColors: {
    thumb: {
      borderColor: '#d9d9d9',
    },
  },
  circleSliderColors: {
    background: '#d3d3d3',
  },
  tabs: {
    indicator: {
      boxShadow: '#ddd',
    },
  },
  dark,
  gradients,
  charts,
};
